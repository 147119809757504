import { classNames } from "@/services/utils"

const ErrorContainer = function ({ className, children }) {
  const containerClasses = `${className || "basis-14 pb-5"} relative`
  return <div className={containerClasses}>{children}</div>
}

export const ErrorHint = function ({ className, children }) {
  const containerClasses = classNames(
    "absolute bottom-[4px] text-red-100 text-xs self-left",
    className || "text-left"
  )
  return <p className={containerClasses}>{children}</p>
}

export default ErrorContainer
