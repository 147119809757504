import { Fragment } from "react"
import { useMultilang } from "@/hooks/useMultilang"
import { TableHead, TableBody } from "./table"
import { HistoryRow, MobileHistoryRow } from "./HistoryRow"

// FIXME
// extract mobile payment history
const OrderHistory = function ({ order }) {
  const { t } = useMultilang()
  const row = [
    t("profile.payment-mthd.table-head.date"),
    t("profile.payment-mthd.table-head.order-number"),
    t("profile.payment-mthd.table-head.amount"),
    t("profile.payment-mthd.table-head.document"),
  ]
  return (
    <div>
      {order?.length > 0 ? (
        <Fragment>
          <table className="hidden tablet:table table-fixed w-full text-xs text-left">
            <TableHead row={row} />
            <TableBody
              data={order}
              renderCell={(obj) => (
                <HistoryRow
                  record={obj}
                  text={t("profile.payment-mthd.table-head.receipt")}
                />
              )}
            />
          </table>

          <ul className="tablet:hidden">
            {order.map((obj) => (
              <li className="text-sm border border-[#f2f2f2] mb-4" key={obj.id}>
                <MobileHistoryRow
                  record={obj}
                  text={t("profile.payment-mthd.table-head.receipt")}
                />
              </li>
            ))}
          </ul>
        </Fragment>
      ) : (
        <p>{t("profile.payment-mthd.no-record")}</p>
      )}
    </div>
  )
}

export default OrderHistory
