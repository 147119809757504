import { useRef } from "react"
import { useClickOutside } from "@/hooks/useClickOutside"
import { useMultilang } from "@/hooks/useMultilang"

const Dropdown = ({ onClick, onRemove, onClickOutside }) => {
  const dropdownRef = useRef()
  useClickOutside(dropdownRef, onClickOutside)
  const { t } = useMultilang()

  return (
    <div
      className="absolute right-0 border border-[#e6e6e6] text-xs min-w-[140px] text-center bg-white z-10"
      ref={dropdownRef}
    >
      <button
        type="button"
        className="border-b py-4 font-bold min-w-full"
        onClick={onClick}
      >
        {t("payment.credit-card.set-as-default")}
      </button>
      <button type="button" className="py-4 min-w-full" onClick={onRemove}>
        {t("payment.credit-card.remove-card")}
      </button>
    </div>
  )
}

export default Dropdown
