import { Fragment, useReducer } from "react"
import { Line } from "rc-progress"
import { classNames } from "@/services/utils"
import { getUploadHost, uploadImage } from "@/services/upload"
import { getUploadPayload } from "@/services/utils"
import {
  INITIAL_STATE,
  finishUpload,
  resetUpload,
  setPercentage,
  startUpload,
  uploadReducer,
} from "@/services/utils/uploadReducer"

export const upload = (apiOptions) => async (file) => {
  const uploadPath = await getUploadHost({
    resType: "resource",
    acl: "public",
    dir: "images",
  })
  const [name] = file?.name?.split(".") || []
  const payload = getUploadPayload(uploadPath, file, name)
  const resource = await uploadImage(uploadPath, payload, apiOptions)

  return [resource, uploadPath]
}

function UploadProgress({ percentage, filename, handleClick }) {
  return percentage || filename ? (
    <Fragment>
      <div className="flex justify-between mb-1">
        <p className="font-bold break-all">{filename}</p>
        <button
          className={classNames(
            "items-end shrink-0 pl-0.5",
            percentage === 100 ? "inline-block" : "hidden"
          )}
          type="button"
          onClick={handleClick}
        >
          <img src="/icon/remove.svg" alt="remove" width={12} height={12} />
        </button>
      </div>
      <Line
        percent={percentage}
        strokeWidth="1"
        strokeColor="#000"
        strokeLinecap="square"
        style={{ display: percentage === 100 ? "none" : "block" }}
      />
    </Fragment>
  ) : null
}

export function ProgressWrapper({ children, handleClick }) {
  const [state, dispatch] = useReducer(uploadReducer, INITIAL_STATE)

  function handleClear() {
    handleClick()
    dispatch(resetUpload())
  }

  const handlePercentage = (val) => dispatch(setPercentage(val))
  const handleUpload = (filename) => dispatch(startUpload(filename))
  const handleFinish = () => dispatch(finishUpload())
  const handleReset = () => dispatch(resetUpload())

  return (
    <>
      {children({
        percentage: state.percentage,
        setPercentage: handlePercentage,
        setFilename: handleUpload,
        handleFinish,
        handleReset,
        uploadStatus: state.uploadStatus,
      })}
      <UploadProgress
        percentage={state.percentage}
        filename={state.fileName}
        handleClick={handleClear}
      />
    </>
  )
}

export default UploadProgress
