import customAxios from "../axios"
import useSWR from "swr"

export const PAYMENT_METHOD_ENDPOINT = "/api/stripe/payment_methods"

const CREDIT_CARD_PAYMENT_TYPE = {
  DEPOSIT: "deposit",
  BALANCE: "balance",
}

const createCreditCardEndpoint = (id, type) =>
  `/api/orders/${id}/payments/credit_card/${type}`

export const getStripeSetupIntent = async function () {
  const endPoint = "/api/stripe/setup_intent"
  const res = await customAxios.post(endPoint)

  return res.data
}

export const deletePaymentMethod = async function (id) {
  const payload = { paymentMethodId: id }
  const res = await customAxios.delete(PAYMENT_METHOD_ENDPOINT, {
    data: payload,
  })

  return res.data
}

export const useStripeCards = function () {
  const { data: res } = useSWR(PAYMENT_METHOD_ENDPOINT, customAxios.get, {
    revalidateOnMount: true,
  })
  const data = res?.data?.data

  return data || []
}

export const makeOrder = async function ({ watchId, payload }) {
  const endPoint = `/api/watches/${watchId}/orders`
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const createDepositPayment = async function (orderId, payload) {
  const type = CREDIT_CARD_PAYMENT_TYPE.DEPOSIT
  const endPoint = createCreditCardEndpoint(orderId, type)
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const createBalancePayment = async function (orderId, payload) {
  const type = CREDIT_CARD_PAYMENT_TYPE.BALANCE
  const endPoint = createCreditCardEndpoint(orderId, type)
  const res = await customAxios.post(endPoint, payload)

  return res.data
}
