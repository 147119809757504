import LinkText from "@/components/common/Link/LinkText"
import { FormatedDate } from "@/components/common/FormatedText"

export function HistoryRow({ record, text }) {
  return (
    <>
      <td className="whitespace-nowrap py-2.5 tablet:px-2 desktop:px-5">
        <FormatedDate value={record.date} />
      </td>
      <td className="tablet:px-2 desktop:px-5">{record.orderNumber}</td>
      <td className="tablet:px-2 desktop:px-5">{record.amount}</td>
      <td className="w-full tablet:px-2 desktop:px-5">
        <LinkText href={record.signedUrl} openNewTab>
          {text}
        </LinkText>
      </td>
    </>
  )
}

export function MobileHistoryRow({ record, text }) {
  return (
    <>
      <div className="flex items-center bg-[#f2f2f2] px-3 py-1.5">
        <p className="text-xs">
          <FormatedDate value={record.date} />
        </p>
      </div>
      <div className="px-3 py-2">
        <div className="flex justify-between">
          <p className="font-bold">{record.orderNumber}</p>
          <p className="mb-1.5">{record.amount}</p>
        </div>
        <LinkText href={record.signedUrl} openNewTab>
          {text}
        </LinkText>
      </div>
    </>
  )
}
