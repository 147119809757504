import { useState } from "react"
import { useSelector } from "react-redux"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import SellBuyNavigationMobile from "./Navigation/SellBuyNavigationMobile"
import ProfileNavigationMobile from "./Navigation/ProfileNavigationMobile"
import { useHasEmptyProfile } from "@/hooks/useBasicProfile"
import dynamic from "next/dynamic"

const NoSSRCookieBanner = dynamic(() => import("./Banner/Cookie"), {
  ssr: false,
})

const NoSSRSmartBanner = dynamic(() => import("./SmartBanner"), {
  ssr: false,
})

export default function IndexLayout({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const isLogin = useSelector((state) => state.user.isLogin)
  const hasUnfilledBasicProfile = useHasEmptyProfile()
  const isLoginAndEmptyProfile = hasUnfilledBasicProfile && isLogin

  return (
    <div className="flex flex-col min-h-screen">
      <NoSSRSmartBanner />
      <Header
        toggle={toggle}
        disabled={isLoginAndEmptyProfile}
        isOpen={isOpen}
      />
      <div className="relative flex flex-col grow z-0">
        <div
          className={`flex flex-col grow transition-all duration-300 overflow-x-hidden ${
            isOpen ? "mr-[265px] ml-[-265px] tablet:mr-0 tablet:ml-0" : ""
          }`}
        >
          <SellBuyNavigationMobile />
          <div className="w-full grow">{children}</div>
          <Footer />
        </div>
        <ProfileNavigationMobile
          show={isOpen}
          onClose={() => setIsOpen(false)}
        />
        <NoSSRCookieBanner />
      </div>
    </div>
  )
}

export const getLayout = (page) => <IndexLayout>{page}</IndexLayout>

export const LayoutWithoutFooter = function ({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const isLogin = useSelector((state) => state.user.isLogin)
  const hasUnfilledBasicProfile = useHasEmptyProfile()
  const isLoginAndEmptyProfile = hasUnfilledBasicProfile && isLogin

  return (
    <div className="flex flex-col min-h-screen">
      <Header
        toggle={toggle}
        disabled={isLoginAndEmptyProfile}
        isOpen={isOpen}
      />
      <div className="relative grow">
        <div
          className={`transition-all duration-300 ${
            isOpen ? "mr-[265px] ml-[-265px] overflow-x-hidden" : ""
          }`}
        >
          <SellBuyNavigationMobile disabled={isLoginAndEmptyProfile} />
          <div className="flex flex-col justify-center tablet:py-14">
            <div>{children}</div>
          </div>
        </div>
        <ProfileNavigationMobile
          show={isOpen}
          onClose={() => setIsOpen(false)}
        />
        <NoSSRCookieBanner />
      </div>
    </div>
  )
}
