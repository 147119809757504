import countryCodeJson from "@/assets/json/countryCode"
import { useMultilang } from "@/hooks/useMultilang"
import { sortByKey } from "@/services/utils"
import groupBy from "lodash/groupBy"
import find from "lodash/find"

export const HONG_KONG = "Hong Kong"

export default function useCountries() {
  const { multilang, filterOption } = useMultilang()
  const countries = sortByKey(countryCodeJson, "nameEN").map((c) => ({
    value: c.value,
    filterValue: filterOption(c, "name"),
    label: multilang(c, "name"),
  }))
  const countryCodes = Object.keys(
    groupBy(
      countryCodeJson
        .map((c) => c["Tel country code"])
        .sort()
        .map((code) => `+${code}`),
      (val) => val
    )
  )

  function findAreaCode(country) {
    const result = find(countryCodeJson, (c) => c.value === country)
    return result ? `+${result["Tel country code"]}` : null
  }

  return {
    countries,
    countryCodes,
    findAreaCode,
  }
}

export const findCountry = (multilang) => (val) => {
  const country = countryCodeJson.find((country) => country.value === val)

  return multilang(country, "name") || val
}

export function getPhaseOneCountries(countries) {
  return countries.filter((option) => option.value === HONG_KONG)
}

export function isNotHk(value) {
  return value !== HONG_KONG
}
