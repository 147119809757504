import {
  PROFILE_SELLER_OFFERS,
  PROFILE_BUYER_OFFERS,
  PROFILE_BUYER_ORDERS,
  PROFILE_SELLER_ORDERS,
  PROFILE_LISTINGS,
  PROFILE_ACCOUNT,
  PROFILE_PAYMENT_METHOD,
  PROFILE_GET_PAID,
  PROFILE_PREFERENCES,
  PROFILE_CHANGE_PASSWORD,
  PROFILE_TEAM,
  PROFILE,
  PROFILE_RATINGS,
  PROFILE_SUBSCRIPTION,
  PROFILE_K_DOLLARS,
} from "@/constants/route"

import { MERCHANT_OWNER, MERCHANT_STAFF } from "@/constants/merchant"
import { SELLER } from "@/constants/user"

import {
  SavedPayments,
  ViewOnlyPayments,
  PaymentFallback,
} from "@/components/Profile/PaymentMethod"

export const menuMap = {
  [MERCHANT_OWNER]: getMerchantOwnerMenu,
  [MERCHANT_STAFF]: getMerchantStaffMenu,
  [SELLER]: getUserMenu,
  default: getUserMenu,
}

export const paymentMap = {
  [MERCHANT_OWNER]: SavedPayments,
  [MERCHANT_STAFF]: ViewOnlyPayments,
  [SELLER]: SavedPayments,
  default: PaymentFallback,
}

export function getMenu({
  getPaidData,
  t,
  role,
  hasRating,
  isPostGracePeriod = false,
}) {
  const baseMenu = defaultMenu({ t })

  if (!role) {
    return baseMenu
  }

  if (isPostGracePeriod) {
    return getGracePeriodEndedMenu({ t })
  }

  const menu = menuMap[role] || menuMap.default

  return baseMenu.concat(menu({ getPaidData, t, hasRating }))
}

function defaultMenu({ t }) {
  return [
    {
      subMenu: [
        {
          link: PROFILE,
          linkText: t("profile-menu.overview"),
        },
      ],
    },
  ]
}

export function getUserMenu({ getPaidData, t, hasRating }) {
  return [
    {
      section: t("profile-menu.buy"),
      subMenu: [
        {
          link: PROFILE_BUYER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
        {
          link: PROFILE_BUYER_OFFERS,
          linkText: t("profile-menu.offers"),
        },
      ],
    },
    {
      section: t("profile-menu.sell"),
      subMenu: [
        {
          link: PROFILE_SELLER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
        {
          link: PROFILE_SELLER_OFFERS,
          linkText: t("profile-menu.offers"),
        },
        {
          link: PROFILE_LISTINGS,
          linkText: t("profile-menu.listings"),
        },
      ],
    },
    {
      section: t("profile-menu.myaccount"),
      subMenu: [
        {
          link: PROFILE_ACCOUNT,
          linkText: t("profile-menu.profile"),
        },
        {
          link: PROFILE_K_DOLLARS,
          linkText: t("profile-menu.k-dollars"),
        },
        ...(hasRating
          ? [
              {
                link: PROFILE_RATINGS,
                linkText: t("profile-menu.ratings"),
              },
            ]
          : []),
        {
          link: PROFILE_SUBSCRIPTION,
          linkText: t("profile-menu.subscription"),
        },
        {
          link: PROFILE_PAYMENT_METHOD,
          linkText: t("profile-menu.payment-mthd"),
        },
        {
          link: PROFILE_GET_PAID,
          linkText: t("profile-menu.get-paid"),
          iconLink: "/icon/notification-alert.svg",
          showIcon:
            getPaidData?.errorHKD ||
            getPaidData?.errorUSD ||
            getPaidData?.idError,
        },
        {
          link: PROFILE_PREFERENCES,
          linkText: t("profile-menu.preference"),
        },
        {
          link: PROFILE_CHANGE_PASSWORD,
          linkText: t("profile-menu.password"),
        },
      ],
    },
  ]
}

export function getMerchantOwnerMenu({ getPaidData, t, hasRating }) {
  return [
    {
      section: t("profile-menu.sell"),
      subMenu: [
        {
          link: PROFILE_SELLER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
        {
          link: PROFILE_SELLER_OFFERS,
          linkText: t("profile-menu.offers"),
        },
        {
          link: PROFILE_LISTINGS,
          linkText: t("profile-menu.listings"),
        },
      ],
    },
    {
      section: t("profile-menu.buy"),
      subMenu: [
        {
          link: PROFILE_BUYER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
        {
          link: PROFILE_BUYER_OFFERS,
          linkText: t("profile-menu.offers"),
        },
      ],
    },
    {
      section: t("profile-menu.my-store"),
      subMenu: [
        {
          link: PROFILE_ACCOUNT,
          linkText: t("profile-menu.store-info"),
        },
        {
          link: PROFILE_K_DOLLARS,
          linkText: t("profile-menu.k-dollars"),
        },
        ...(hasRating
          ? [
              {
                link: PROFILE_RATINGS,
                linkText: t("profile-menu.ratings"),
              },
            ]
          : []),
        {
          link: PROFILE_SUBSCRIPTION,
          linkText: t("profile-menu.subscription"),
        },
        {
          link: PROFILE_PAYMENT_METHOD,
          linkText: t("profile-menu.payment-mthd"),
        },
        {
          link: PROFILE_GET_PAID,
          linkText: t("profile-menu.get-paid"),
          iconLink: "/icon/notification-alert.svg",
          showIcon:
            getPaidData?.errorHKD ||
            getPaidData?.errorUSD ||
            getPaidData?.idError,
        },
        {
          link: PROFILE_PREFERENCES,
          linkText: t("profile-menu.merchant-preference"),
        },
        {
          link: PROFILE_TEAM,
          linkText: t("profile-menu.team"),
        },
        {
          link: PROFILE_CHANGE_PASSWORD,
          linkText: t("profile-menu.password"),
        },
      ],
    },
  ]
}

export function getMerchantStaffMenu({ getPaidData, t }) {
  return [
    {
      section: t("profile-menu.buy"),
      subMenu: [
        {
          link: PROFILE_BUYER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
        // {
        //   link: PROFILE_BUYER_OFFERS,
        //   linkText: t("profile-menu.offers"),
        // },
      ],
    },
    {
      section: t("profile-menu.sell"),
      subMenu: [
        {
          link: PROFILE_SELLER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
        // {
        //   link: PROFILE_SELLER_OFFERS,
        //   linkText: t("profile-menu.offers"),
        // },
        {
          link: PROFILE_LISTINGS,
          linkText: t("profile-menu.listings"),
        },
      ],
    },
    {
      section: t("profile-menu.my-store"),
      subMenu: [
        {
          link: PROFILE_ACCOUNT,
          linkText: t("profile-menu.store-info"),
        },
        {
          link: PROFILE_PAYMENT_METHOD,
          linkText: t("profile-menu.payment-mthd"),
        },
        {
          link: PROFILE_GET_PAID,
          linkText: t("profile-menu.get-paid"),
          iconLink: "/icon/notification-alert.svg",
          showIcon:
            getPaidData?.errorHKD ||
            getPaidData?.errorUSD ||
            getPaidData?.idError,
        },
        {
          link: PROFILE_CHANGE_PASSWORD,
          linkText: t("profile-menu.password"),
        },
      ],
    },
  ]
}

export function getGracePeriodEndedMenu({ t }) {
  return [
    {
      section: t("profile-menu.buy"),
      subMenu: [
        {
          link: PROFILE_BUYER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
      ],
    },
    {
      section: t("profile-menu.sell"),
      subMenu: [
        {
          link: PROFILE_SELLER_ORDERS,
          linkText: t("profile-menu.orders"),
        },
      ],
    },
    {
      section: t("profile-menu.my-store"),
      subMenu: [
        {
          link: PROFILE_SUBSCRIPTION,
          linkText: t("profile-menu.subscription"),
          iconLink: "/icon/notification-alert.svg",
          showIcon: true,
        },
      ],
    },
  ]
}
