import React, { useState } from "react"
import Link from "next/link"
import { ToggleMenu } from "./ToggleMenu"
import { useMultilang } from "@/hooks/useMultilang"
import {
  PrivacyPolicy,
  TermsAndCondition,
} from "@/components/common/DynamicContent"

export default function Footer() {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useMultilang()
  const copyrightName = `KRONOS 8 LIMITED ${new Date().getFullYear()}`
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <footer className="bg-gray-100 w-full">
      <div className="container grid py-[50px] tablet:grid-cols-6 tablet:py-[38px]">
        <div className="hidden tablet:block tablet:col-span-1 pr-10">
          <img
            src="/image/footer-logo.png"
            alt="KRONOS8"
            className="max-w-full"
          />
          <div className="flex mt-3">
            <div className="mr-4 cursor-pointer">
              <a
                href="https://www.facebook.com/Kronos8limited/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/icon/facebook.svg"
                  alt="faceboook"
                  width={8}
                  height={8}
                />
              </a>
            </div>
            <div className="mr-4 cursor-pointer">
              <a
                href="https://www.instagram.com/kronos8official/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/icon/instagram.svg"
                  alt="instagram"
                  width={15}
                  height={15}
                />
              </a>
            </div>
            <div className="cursor-pointer">
              <a
                href="https://twitter.com/kronos8official"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/icon/x.png" alt="x" width={15} height={15} />
              </a>
            </div>
          </div>
        </div>
        <ToggleMenu></ToggleMenu>
        <div className="col-span-1">
          <div className="mb-3">
            <p className="text-[13px] font-bold">{t("footer.paymethod")}</p>
          </div>
          <div className="flex">
            <div className="flex mr-4">
              <img
                src="/icon/master-card.svg"
                alt="Master Card"
                width={28}
                height={20}
              />
            </div>
            <div className="flex mr-4">
              <img src="/icon/visa.svg" alt="Visa" width={28} height={20} />
            </div>
            <div className="flex mr-4">
              <img src="/icon/bank.svg" alt="Bank" width={28} height={20} />
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-[#404040] text-white py-5 tablet:grid tablet:grid-cols-6 tablet:bg-gray-100 tablet:text-black-300 tablet:pt-0">
        <div className="tablet:flex tablet:col-start-2 tablet:col-span-4">
          <ul
            className={`transition-all duration-500 easy-out overflow-hidden tablet:flex tablet:items-center tablet:max-h-96 ${
              isOpen ? "max-h-96" : "max-h-4"
            }`}
          >
            <li className="relative w-36 text-xs mb-4 tablet:hidden">
              <p>{t("footer.legal-and-sitemap")}</p>
              <div
                className={`absolute right-0 top-0 duration-500 tablet:hidden ${
                  isOpen ? "rotate-180" : "rotate-0"
                }`}
              >
                <img
                  src="/icon/arrow-down-white.svg"
                  alt="arrow"
                  width={12}
                  height={12}
                  onClick={toggle}
                />
              </div>
            </li>
            <li className="text-xs mb-4 tablet:mb-0">
              <PrivacyPolicy noUnderline />
            </li>
            <li className="hidden tablet:mx-4 tablet:block">•</li>
            <li className="text-xs mb-4 tablet:mb-0">
              <TermsAndCondition noUnderline />
            </li>
            <li className="hidden tablet:mx-4 tablet:block">•</li>
            <li className="text-xs mb-4 tablet:mb-0">
              <Link href="/sitemap">
                <a> {t("footer.sitemap")} </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden text-xs tablet:flex tablet:items-center">
          © {copyrightName}
        </div>
        <div className="flex justify-between mt-4 tablet:hidden">
          <div className="flex">
            <div className="mr-3">
              <a
                href="https://www.facebook.com/Kronos8limited/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/icon/facebook-white.svg"
                  alt="faceboook"
                  className="w-4 h-4"
                  width={10}
                  height={15}
                />
              </a>
            </div>
            <div className="mr-3">
              <a
                href="https://www.instagram.com/kronos8official/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/icon/instagram-white.svg"
                  alt="instagram"
                  className="w-4 h-4"
                  width={15}
                  height={15}
                />
              </a>
            </div>
            <div>
              <a
                href="https://twitter.com/kronos8official"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/icon/x-white.png"
                  alt="instagram"
                  className="w-4 h-4"
                  width={15}
                  height={15}
                />
              </a>
            </div>
          </div>
          <p className="text-xs text-white">© {copyrightName}</p>
        </div>
      </div>
    </footer>
  )
}
