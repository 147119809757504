import { Controller, useForm } from "react-hook-form"
import Popup from "../common/Popup"
import Button from "@/components/common/Button"
import { ReactSelect } from "@/components/common/Form"
import langJson from "@/assets/json/lang"
import useCountries from "@/hooks/useCountries"
import { useEffect } from "react"
import useUserPreference from "@/hooks/useUserPeference"
import { useMultilang } from "@/hooks/useMultilang"

export default function UserPreferencePopup({ show, onClose, onSubmit }) {
  const { t } = useMultilang()
  const { countries } = useCountries()
  const { preference, onSubmit: submit } = useUserPreference()
  const { control, formState, handleSubmit, setValue } = useForm({
    shouldUnregister: true,
  })

  const submitPreference = async (data, e) => {
    e.preventDefault()
    onSubmit(() => {
      submit(data)
      onClose()
    })
  }

  function initForm() {
    for (const k in preference) {
      setValue(k, preference[k])
    }
  }

  useEffect(() => {
    if (show) {
      initForm()
    }
  }, [show])

  useEffect(() => {
    initForm()
  }, [setValue, preference])

  return (
    <Popup show={show}>
      <div className="max-w-full tablet:max-w-sm">
        <div className="flex justify-between pb-2.5 mb-2.5">
          <p className="flex items-center font-bold text-sm uppercase">
            {t("popup.preference.heading")}
          </p>
          <button onClick={onClose}>
            <img src="/icon/cancel.svg" alt="close" width={10} height={10} />
          </button>
        </div>
        <form onSubmit={handleSubmit(submitPreference)}>
          <div className="grid gap-y-5 text-sm w-[358px] mb-5">
            <div className="relative">
              <Controller
                control={control}
                name="shippingRegion"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    ref={null}
                    options={countries}
                    label={t("label.region")}
                    error={formState.errors.shippingRegion}
                    onChange={(data) => {
                      field.onChange(data.value)
                    }}
                  />
                )}
              />
            </div>
            <div className="relative">
              <Controller
                control={control}
                name="language"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    ref={null}
                    options={langJson}
                    label={t("label.language")}
                    getOptionLabel={(opt) => opt.display}
                    error={formState.errors.language}
                    onChange={(data) => {
                      field.onChange(data.value)
                    }}
                  />
                )}
              />
            </div>
            <div className="relative">
              <Controller
                control={control}
                name="currency"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    ref={null}
                    options={["HKD", "USD"].map((opt) => ({
                      value: opt,
                      label: opt,
                    }))}
                    label={t("label.currency")}
                    error={formState.errors.currency}
                    onChange={(data) => {
                      field.onChange(data.value)
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <Button type="submit" className="min-w-[142px]">
              {t("btn.update")}
            </Button>
          </div>
        </form>
      </div>
    </Popup>
  )
}
