import Link from "next/link"
import { DisabledLink } from "@/components/common/Link"
import { useMultilang } from "@/hooks/useMultilang"
import HighlightLink from "./HighlightLink"

const SellBuyNavigationMobile = ({ disabled = false }) => {
  const { t } = useMultilang()
  const HeaderLink = disabled ? DisabledLink : Link
  return (
    <div className="z-[11] flex bg-[#1f1f1f] py-3.5 tablet:hidden">
      <div className="flex justify-center w-1/2">
        <HeaderLink href="/watch" passHref>
          <HighlightLink
            className="text-white text-sm"
            linkPrefix={["/watch", "/watch/[id]"]}
          >
            {t("header.buy")}
          </HighlightLink>
        </HeaderLink>
      </div>
      <div className="flex justify-center w-1/2">
        <HeaderLink href="/sell" passHref>
          <HighlightLink
            className="text-white text-sm"
            linkPrefix={["/sell", "/sell/watch"]}
          >
            {t("header.sell")}
          </HighlightLink>
        </HeaderLink>
      </div>
    </div>
  )
}

export default SellBuyNavigationMobile
