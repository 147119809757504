// https://react-select.com/props
import { classNames } from "@/services/utils"
import { useMemo, useState, useRef, forwardRef } from "react"
import isEquel from "lodash/isEqual"
import toString from "lodash/toString"
import Select from "react-select"

const ReactSelectFieldset = forwardRef((props, ref) => {
  const {
    children,
    displayName,
    error,
    labelMoveTop,
    dangerStyle,
    className,
    borderColor,
  } = props
  const fieldsetClasses = classNames(
    `border relative `,
    error ? dangerStyle : borderColor || "border-gray-300",
    className
  )

  const labelClass = useMemo(() => {
    const baseClass = `absolute z-10  transition-top  ease-in duration-200`
    const left = "left-2 px-1"
    if (labelMoveTop) {
      return classNames(
        `${baseClass} ${left} top-[0px] text-[10px] ${
          error ? dangerStyle : "text-ochre-200"
        }`,
        error && dangerStyle
      )
    } else {
      const top = "top-2.5"
      return classNames(
        `${baseClass} ${top} ${left} text-sm  ${
          error ? dangerStyle : "text-gray-700"
        } pointer-events-none`,
        error && dangerStyle
      )
    }
  }, [labelMoveTop, error, dangerStyle])

  return (
    <fieldset className={fieldsetClasses} ref={ref}>
      {displayName ? (
        <legend className={labelClass}>{displayName}</legend>
      ) : null}
      {children}
    </fieldset>
  )
})

ReactSelectFieldset.displayName = "ReactSelectFieldset"

const ReactSelect = ({
  options,
  label,
  error,
  theme,
  fieldClassName,
  borderColor,
  isMulti = false,
  ...rest
}) => {
  const [focus, setFocus] = useState(false)
  const selectRef = useRef()

  function hasTheme(val) {
    return theme && theme.split(",").indexOf(val) !== -1
  }

  const customStyles = {
    input: (provided) => {
      return {
        ...provided,
        color: hasTheme("dark") ? "#fff" : provided.color,
        // paddingTop: '12px',
        // paddingBottom: '4px',
      }
    },
    singleValue: (provided) => {
      return {
        ...provided,
        paddingLeft: "2px",
        position: "relative",
        top: "2px",
        color: hasTheme("dark") ? "#fff" : "#000",
      }
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        display: "none",
      }
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        color: "#666",
      }
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        paddingTop: isMulti ? "0px" : "12px",
        paddingBottom: "0px",
        padding: " 8px",
      }
    },
    control: (provided) => {
      const isSelectVisible = selectRef.current && selectRef.current.clientWidth
      const maxWidth = isSelectVisible
        ? `${selectRef?.current?.clientWidth}px`
        : "none"

      return {
        ...provided,
        // borderRadius: '0px',
        border: "None",
        boxShadow: "None",
        minHeight: "None",
        backgroundColor: hasTheme("transparent")
          ? "transparent"
          : provided.backgroundColor,
        maxWidth,
      }
    },
    noOptionsMessage: (provided) => {
      return {
        ...provided,
      }
    },
    option: (provided, { isFocused, isSelected, isDisabled }) => ({
      ...provided,
      color: "#333",
      cursor: isDisabled ? "not-allowed" : provided.cursor,
      backgroundColor: isSelected
        ? "#EFDFC4"
        : isFocused
        ? "#F5F2EC"
        : undefined,

      ":active": {
        ...provided[":active"],
        backgroundColor: isFocused ? "#EFDFC4" : undefined,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20, // > 10 over the input label
    }),
  }

  function onfocus(e) {
    setFocus(true)
    if (rest.onFocus) rest.onFocus(e)
  }

  function onblur(e) {
    setFocus(false)
    if (rest.onBlur) rest.onBlur(e)
  }

  function getOptionValue(val) {
    for (const option of options) {
      const optionValue = option.value
      if (typeof optionValue == "object" && optionValue) {
        if (isEquel(optionValue, val)) return option
      } else if (optionValue == val) {
        return option
      }
    }
    return null
  }

  // react-select value is in option format, custom function to return value in option format
  function findValue(val) {
    if (!val) return null
    if (Array.isArray(val)) {
      const results = []
      for (const v of val) {
        const optionValue = getOptionValue(v)
        if (optionValue) results.push(optionValue)
      }
      return results
    } else {
      return getOptionValue(val)
    }
  }

  function filter(option, val) {
    const _option = toString(option).trim().toLowerCase()
    const _val = val.trim().toLowerCase()
    return _option.match(_val)
  }

  function filterOption(option, val) {
    const { filterValue } = option.data
    if (!filterValue) return filter(option.label, val)
    for (const opt of filterValue) {
      if (filter(opt, val)) return true
    }
    return false
  }

  const hasValue = useMemo(() => {
    return rest.value
  }, [rest.value])

  return (
    <ReactSelectFieldset
      displayName={label}
      error={error}
      padding="0"
      labelMoveTop={focus || hasValue}
      className={fieldClassName}
      borderColor={borderColor}
      dangerStyle="border-red-100 text-red-100"
      ref={selectRef}
    >
      <Select
        styles={customStyles}
        options={options}
        placeholder={null}
        instanceId={label}
        isMulti={isMulti}
        {...rest}
        filterOption={(option, query) => filterOption(option, query)}
        value={findValue(rest.value)}
        onFocus={onfocus}
        onBlur={onblur}
      />
    </ReactSelectFieldset>
  )
}

const BaseSelect = function ({ options, customStyles = {}, ...rest }) {
  return (
    <Select
      styles={{
        control: (provided) => {
          return {
            ...provided,
            border: "None",
            boxShadow: "None",
            minHeight: "None",
            backgroundColor: "transparent",
          }
        },
        container: (provided) => {
          return {
            ...provided,
            paddingTop: "4px",
            paddingBottom: "0px",
            backgroundColor: "transparent",
          }
        },
        indicatorSeparator: (provided) => {
          return {
            ...provided,
            display: "none",
          }
        },
        dropdownIndicator: (provided) => {
          return {
            ...provided,
            color: "#666",
            padding: provided.padding,
          }
        },
        noOptionsMessage: (provided) => {
          return {
            ...provided,
            padding: provided.padding,
          }
        },
        option: (provided, { isFocused, isSelected }) => ({
          ...provided,
          color: "#333",
          padding: provided.padding,
          backgroundColor: isSelected
            ? "#EFDFC4"
            : isFocused
            ? "#F5F2EC"
            : undefined,

          ":active": {
            ...provided[":active"],
            backgroundColor: isFocused ? "#EFDFC4" : undefined,
          },
        }),
        ...customStyles,
      }}
      options={options}
      {...rest}
    />
  )
}

export { ReactSelect, ReactSelectFieldset, BaseSelect }
