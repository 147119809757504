import { getDescendantProp } from "@/services/utils"
import { classNames } from "@/services/utils"

const TextAreaField = ({
  name,
  displayName,
  register,
  options,
  errors = {},
  dangerStyle,
  inputStyle,
  fieldsetStyle,
  placeholder,
  disabled = false,
  children,
  fieldError = false,
  ...rest
}) => {
  const fieldsetClasses = classNames("relative", fieldsetStyle)

  const registerResult = register(name, options)
  const hasError = getDescendantProp(errors, name) || fieldError

  const inputClasses = classNames(
    "w-full block rounded-none resize-none pl-3 pr-1 pt-4 pb-2 h-[100px] peer border outline-none disabled:bg-gray-200 disabled:bg-opacity-40 ",
    hasError
      ? "border-red-100 text-red-100 placeholder:text-red-100"
      : "border-gray-300 placeholder:text-gray-700",
    inputStyle
  )
  return (
    <fieldset className={fieldsetClasses}>
      <textarea
        id={name}
        className={inputClasses}
        autoComplete="off"
        disabled={disabled}
        {...rest}
        placeholder={placeholder}
        {...registerResult}
      />
      {children}
    </fieldset>
  )
}

export { TextAreaField }
