import { useState, Fragment, useEffect, useRef } from "react"
import { useRouter } from "next/router"
import { useSelector, useDispatch } from "react-redux"
import Link from "next/link"
import Button from "@/components/common/Button"
import { Controller, useForm } from "react-hook-form"
import { ReactSelect } from "@/components/common/Form"
import langJson from "@/assets/json/lang"
import useUserPreference from "@/hooks/useUserPeference"
import { logout } from "@/store/user"
import customAxios from "@/services/axios"
import { useMultilang } from "@/hooks/useMultilang"
import useCountries from "@/hooks/useCountries"
import { LeavePagePopup } from "@/components/Popup"
import { getMenu } from "../Profile/utils"
import { useIsPostGracePeriod, useRole } from "@/hooks/useAuthentication"

const ProfileNavigationMobile = ({ show, onClose }) => {
  const { preference, onSubmit, displayLangTranslated } = useUserPreference()
  const [leavePagePopupShow, setLeavePagePopupShow] = useState(false)
  const submitCallback = useRef()
  const router = useRouter()
  const { t } = useMultilang()
  const [isOpen, setIsOpen] = useState(false)
  const isLogin = useSelector((state) => state.user.isLogin)
  const getPaidData = useSelector((state) => state.user.getPaidData)
  const hasRating = useSelector((state) => state.user.userRating?.hasRating)
  const { countries } = useCountries()
  const { control, formState, handleSubmit, setValue } = useForm({
    shouldUnregister: true,
  })
  const role = useRole()
  const isPostGracePeriod = useIsPostGracePeriod()

  async function onSubmitPreference(data, e) {
    e.preventDefault()
    if (["/sell/watch"].indexOf(router.route) !== -1) {
      setLeavePagePopupShow(true)
      submitCallback.current = () => confirmSubmitPreference(data)
    } else {
      confirmSubmitPreference(data)
    }
    onClose()
  }

  async function confirmSubmitPreference(data) {
    await onSubmit(data)
    setIsOpen(false)
  }

  function initForm() {
    for (const k in preference) {
      setValue(k, preference[k])
    }
  }

  useEffect(() => {
    initForm()
  }, [preference])

  useEffect(() => {
    if (isOpen) {
      initForm()
    }
  }, [isOpen])

  const menu = getMenu({ getPaidData, t, role, hasRating, isPostGracePeriod })
  const menuEl = menu.map((menuObj, i) => {
    const subMenuEl = menuObj.subMenu.map((subMenuObj) => {
      const isActive = router.pathname === subMenuObj.link
      return (
        <li
          className={`mt-[-1px] ${
            subMenuObj.last ? "border-t" : "border-y"
          } border-gray-500`}
          key={subMenuObj.link}
        >
          <Link href={subMenuObj.link}>
            <a
              className={`flex ${
                isActive ? "font-bold" : ""
              } block text-sm text-white px-[50px] py-4`}
              onClick={() => onClose()}
            >
              {subMenuObj.linkText}
              {subMenuObj.showIcon ? (
                <img src={subMenuObj.iconLink} alt="" width={18} height={18} />
              ) : null}
            </a>
          </Link>
        </li>
      )
    })

    return (
      <Fragment key={i}>
        {menuObj.section && (
          <li className="text-xs text-[#7b7b7b] font-bold px-4 py-4 border-b border-gray-500">
            {menuObj.section}
          </li>
        )}
        {subMenuEl}
      </Fragment>
    )
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const dispatch = useDispatch()
  const signout = async () => {
    await customAxios.put("/api/logout")
    dispatch(logout())
    router.push("/")
    onClose()
  }

  return (
    <div
      className={`absolute z-30 top-0 right-0 bg-black-300 h-full transition-all duration-300 overflow-x-hidden tablet:hidden ${
        show ? "w-[265px]" : "w-0"
      }`}
    >
      {isLogin ? null : (
        <div className="text-xs text-[#7b7b7b] font-bold px-4 py-4 border-b border-gray-500">
          <Link href="/login">
            <a className="uppercase" onClick={() => onClose()}>
              {t("btn.signin")}
            </a>
          </Link>
        </div>
      )}
      <div
        className={`text-xs text-white font-bold px-4 py-4 border-b border-gray-500 ${
          isOpen ? "bg-black-900" : ""
        }`}
        onClick={toggle}
      >
        {displayLangTranslated} | {preference.currency}
      </div>
      <div
        className={`transition-all duration-500 easy-out overflow-hidden ${
          isOpen ? "max-h-96" : "max-h-0"
        }`}
      >
        <form onSubmit={handleSubmit(onSubmitPreference)}>
          <div className="text-xs text-white px-4 py-4 border-b border-gray-500">
            <div className="grid gap-y-2.5 w-[235px] mb-5">
              <div className="relative">
                <Controller
                  control={control}
                  name="shippingRegion"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      ref={null}
                      options={countries}
                      theme="dark,transparent"
                      label={t("label.region")}
                      error={formState.errors.shippingRegion}
                      onChange={(data) => {
                        field.onChange(data.value)
                      }}
                    />
                  )}
                />
              </div>
              <div className="relative">
                <Controller
                  control={control}
                  name="language"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      ref={null}
                      options={langJson}
                      theme="dark,transparent"
                      label={t("label.language")}
                      getOptionLabel={(opt) => opt.display}
                      error={formState.errors.language}
                      onChange={(data) => {
                        field.onChange(data.value)
                      }}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              <div className="relative">
                <Controller
                  control={control}
                  name="currency"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      ref={null}
                      theme="dark,transparent"
                      options={["HKD", "USD"].map((opt) => ({
                        value: opt,
                        label: opt,
                      }))}
                      label={t("label.currency")}
                      error={formState.errors.currency}
                      onChange={(data) => {
                        field.onChange(data.value)
                      }}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <Button type="submit" className="min-w-[142px] bg-[#707070]">
                {t("btn.update")}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <LeavePagePopup
        show={leavePagePopupShow}
        onConfirm={() =>
          (submitCallback.current && submitCallback.current()) ||
          setLeavePagePopupShow(false)
        }
        onCancel={() => setLeavePagePopupShow(false) || setIsOpen(false)}
      />
      {isLogin ? (
        <ul>
          {menuEl}
          <li
            className="text-sm text-[#7b7b7b] font-bold px-4 py-4 cursor-pointer"
            onClick={signout}
          >
            {t("menu.sign-out")}
          </li>
        </ul>
      ) : null}
    </div>
  )
}

export default ProfileNavigationMobile
