import { classNames } from "@/services/utils"

const Fieldset = function ({
  children,
  displayName,
  error,
  dangerStyle,
  className,
}) {
  const fieldsetClasses = classNames(
    "border",
    error ? dangerStyle : "border-gray-300",
    className
  )
  const legendClasses = classNames(
    "absolute text-xs px-2 -ml-2",
    error && dangerStyle
  )

  return (
    <fieldset className={fieldsetClasses}>
      {displayName ? (
        <legend className={legendClasses}>{displayName}</legend>
      ) : null}
      {children}
    </fieldset>
  )
}

export default Fieldset
