const Checkbox = function ({
  name = "",
  register,
  options,
  error = false,
  ...rest
}) {
  return (
    <div className="base-checkbox">
      <label>
        <input
          type="checkbox"
          id={name}
          {...register(name, options)}
          {...rest}
        />
        <span className={error ? "error" : ""}></span>
      </label>
    </div>
  )
}

export default Checkbox
