import {
  FINISH_UPLOAD,
  RESET_UPLOAD,
  SET_PERCENTAGE,
  START_UPLOAD,
} from "./constants"

export const setPercentage = (val) => ({
  type: SET_PERCENTAGE,
  payload: val,
})

export const startUpload = (val) => ({ type: START_UPLOAD, payload: val })

export const finishUpload = () => ({ type: FINISH_UPLOAD })

export const resetUpload = () => ({ type: RESET_UPLOAD })
