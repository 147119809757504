import {
  FINISH_UPLOAD,
  RESET_UPLOAD,
  SET_PERCENTAGE,
  START_UPLOAD,
  STATUS_FINISHED,
  STATUS_IDLE,
  STATUS_UPLOADING,
} from "./constants"

export const isUploading = (status) => status === STATUS_UPLOADING

export const INITIAL_STATE = {
  percentage: 0,
  uploadStatus: STATUS_IDLE,
  fileName: "",
}

export default function uploadReducer(state, action) {
  switch (action.type) {
    case START_UPLOAD: {
      return {
        ...state,
        uploadStatus: STATUS_UPLOADING,
        fileName: action.payload,
      }
    }
    case SET_PERCENTAGE: {
      return { ...state, percentage: action.payload }
    }
    case FINISH_UPLOAD: {
      return {
        ...state,
        uploadStatus: STATUS_FINISHED,
      }
    }
    case RESET_UPLOAD:
      return INITIAL_STATE

    default:
      return state
  }
}
