import React, { useState } from "react"
import Link from "next/link"
import { useMultilang } from "@/hooks/useMultilang"

export const ToggleMenu = function () {
  const { t } = useMultilang()
  function toggle(index) {
    const newToggleArray = [...toggleArray]
    newToggleArray[index] = !newToggleArray[index]
    setToggleArray(newToggleArray)
  }
  const menu = [
    {
      section: t("footer.buy"),
      subMenu: [
        {
          name: t("footer.buyer-protect"),
          link: "/buyer-protection",
        },
        {
          name: t("footer.auth-service"),
          link: "/authentication-service",
        },
        {
          name: t("footer.secure-pay"),
          link: "/service-secure-payment",
        },
      ],
    },
    {
      section: t("footer.sell"),
      subMenu: [
        {
          name: t("footer.how-to-sell"),
          link: `/how-to-sell`,
        },
        {
          name: t("footer.be-merchant"),
          link: "/become-a-merchant",
        },
        {
          name: t("footer.sell-watch"),
          link: "/sell",
        },
      ],
    },
    {
      section: t("footer.about-k8"),
      subMenu: [
        {
          name: t("footer.about-us"),
          link: "/about-us",
        },
      ],
    },
    {
      section: t("footer.help"),
      subMenu: [
        {
          name: t("footer.faq"),
          link: "/faq",
        },
        {
          name: t("footer.contact-us"),
          link: "/contact-us",
        },
      ],
    },
  ]
  const [toggleArray, setToggleArray] = useState(menu.map(() => false))
  const isOpen = (index) => toggleArray[index]

  let menuEl = []

  menuEl = menu.map((menuObj, index) => {
    const subMenuEl = menuObj.subMenu.map((subMenu) => {
      return (
        <li className="mx-3 mb-3 tablet:mx-0 tablet:mb-2" key={subMenu.name}>
          <Link href={subMenu.link}>
            <a className="text-black-300 text-[13px]">{subMenu.name}</a>
          </Link>
        </li>
      )
    })
    return (
      <div className="tablet:mr-6 grow" key={index}>
        <div className="flex justify-between mb-3 tablet:mb-2">
          <p className="text-black-300 text-[13px] font-bold">
            {menuObj.section}
          </p>
          <div
            className={`duration-500 ${
              isOpen(index) ? "rotate-180" : "rotate-0"
            } tablet:hidden`}
          >
            <img
              src="/icon/arrow-down.svg"
              alt="arrow"
              width={12}
              height={12}
              onClick={() => toggle(index)}
            />
          </div>
        </div>
        <ul
          className={`transition-all duration-500 easy-out overflow-hidden tablet:max-h-96 ${
            isOpen(index) ? "max-h-96" : "max-h-0"
          }`}
        >
          {subMenuEl}
        </ul>
      </div>
    )
  })

  return <div className="tablet:flex tablet:col-span-4">{menuEl}</div>
}
