export const TableHead = ({ row }) => {
  return (
    <thead>
      <tr className="bg-neutral-50 whitespace-nowrap">
        {row.map((head, i) => (
          <th key={i} className={`py-2.5 tablet:px-2 desktop:px-5`}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export const TableBody = ({ data, renderCell }) => {
  return (
    <tbody className="text-left divide-y divide-[#e0e0e0]">
      {data.map((obj) => (
        <tr key={obj.id}>{renderCell(obj)}</tr>
      ))}
    </tbody>
  )
}
