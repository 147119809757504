import { classNames, getDescendantProp } from "@/services/utils"
import { useState } from "react"

export const LegendInput = function ({
  name,
  displayName,
  register,
  options,
  errors = {},
  dangerStyle,
  inputStyle,
  fieldsetStyle,
  disabled = false,
  labelStayTop = false,
  havePlacehoder = false,
  children,
  fieldError = false,
  ...rest
}) {
  const fieldsetClasses = classNames("relative", fieldsetStyle)
  const [focus, setFocus] = useState(false)
  const hasError = getDescendantProp(errors, name) || fieldError

  let inActiveStyle =
    focus || labelStayTop
      ? ""
      : `peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:text-sm  ${
          hasError ? "text-red-100" : "peer-placeholder-shown:text-gray-700"
        }`

  let activeStyle = `top-0 text-[10px] ${
    hasError ? "text-red-100" : "text-ochre-200"
  }`
  const labelStyle = ` ${activeStyle} ${inActiveStyle}`
  const labelClasses = classNames(
    "absolute left-2 px-1 transition-top  ease-in duration-200",
    hasError ? dangerStyle : "",
    labelStyle
  )
  const registerResult = register(name, options)

  function onBlur(...args) {
    setFocus(false)
    registerResult.onBlur(...args)
  }
  const inputClasses = classNames(
    "base-legendInput-input peer",
    hasError ? dangerStyle : "border-gray-300",
    inputStyle
  )
  return (
    <fieldset className={fieldsetClasses}>
      <input
        id={name}
        type="text"
        className={inputClasses}
        autoComplete="off"
        disabled={disabled}
        {...rest}
        placeholder={havePlacehoder ? rest.placeholder : " "}
        onFocus={() => setFocus(true)}
        {...registerResult}
        onBlur={onBlur}
      />
      <label htmlFor={name} className={labelClasses}>
        {displayName}
      </label>
      {typeof children === "function" ? children(focus) : children}
    </fieldset>
  )
}

export const LabelInput = function ({
  name,
  displayName,
  register,
  options,
  errors = {},
  dangerStyle,
  fieldsetStyle,
  labelStyle,
  inputStyle,
  ...rest
}) {
  const fieldsetClasses = classNames(
    "text-left flex flex-col mb-4",
    fieldsetStyle
  )
  const labelClasses = classNames("font-bold mb-2", labelStyle)
  const inputClasses = classNames(
    "border rounded-none pl-3 py-2 outline-none",
    getDescendantProp(errors, name) ? dangerStyle : "border-gray-300",
    inputStyle
  )

  return (
    <fieldset className={fieldsetClasses}>
      <label htmlFor={name} className={labelClasses}>
        {displayName}
      </label>
      <input
        {...register(name, options)}
        id={name}
        className={inputClasses}
        {...rest}
      />
    </fieldset>
  )
}

export const Input = function ({
  name = "",
  register,
  centerAlign = false,
  options = {},
  className,
  ...rest
}) {
  const verticalPadding = centerAlign ? "pt-[13px] pb-[13px]" : "pt-4 pb-[2px]"
  const containerClasses = classNames(
    `w-full pl-3 pr-1 ${verticalPadding} placeholder:text-gray-700 placeholder:text-sm disabled:bg-gray-200 disabled:bg-opacity-40`,
    className
  )
  const hookFields = register ? register(name, options) : {}

  return <input className={containerClasses} {...hookFields} {...rest} />
}

const withDanger = (Component) => {
  WrappedComponent.displayName = `${Component.name}WithDanger`
  function WrappedComponent(props) {
    const { dangerStyle = "", errors = {}, fieldError = false, ...rest } = props
    const hasError = errors[props.name] || fieldError
    const baseStyles = "border rounded-none"
    const combinedClasses = classNames(
      props.className,
      hasError ? dangerStyle : "border-gray-300",
      baseStyles
    )

    return <Component {...rest} className={combinedClasses} />
  }

  return WrappedComponent
}

export const BaseInput = withDanger(Input)
