import { useState, useEffect } from "react"
import { getDynamicContent } from "@/services/contents"
import { useMultilang } from "@/hooks/useMultilang"

function useDynamicContent(endPoint) {
  const [data, setData] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    async function fetchDataInEffect() {
      try {
        const res = await getDynamicContent(endPoint)
        setData(res)
      } catch (error) {
        console.log(error)
        setError(error)
      }
    }

    fetchDataInEffect()
  }, [])

  return [data, error]
}

function DynamicContent({ render, endPoint }) {
  const [data] = useDynamicContent(endPoint)
  const { multilang } = useMultilang()
  const link = multilang(data, "contentUri") || "/"

  return render(link)
}

export default DynamicContent
