import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"
import { useFormContext, Controller } from "react-hook-form"
import { useMultilang } from "@/hooks/useMultilang"
import ErrorContainer, { ErrorHint } from "../ErrorHint"
import { classNames } from "@/services/utils"

const appearance = (opts = {}) => ({
  style: {
    base: {
      color: "#000000",
      fontSize: "14px",
      fontFamily: "Montserrat",
      "::placeholder": {
        color: "#707070",
      },
    },
    invalid: {
      color: "#a70000",
    },
  },
  ...opts,
})

const Border = ({ children, error, className }) => {
  return (
    <div
      className={classNames(
        "border base-stripe-container",
        error ? "border-red-100" : "border-[#ccc]",
        className
      )}
    >
      {children}
    </div>
  )
}

const handleChange =
  ({ fieldName, setError, clearErrors, t }) =>
  (field) =>
  (status) => {
    const isFilled = status.complete && !status.empty

    if (status.error) {
      return setError(fieldName, {
        message: t("profile.payment-mthd.card-field.invalid"),
        type: status.error.type,
      })
    }

    if (!status.error) {
      clearErrors(fieldName)
    }

    return isFilled ? field.onChange("filled") : field.onChange("")
  }

const ErrorMsg = ({ formState }) => {
  const { t } = useMultilang()

  return (
    <ErrorHint>
      {formState.errors?.cardHolder?.message ||
        formState.errors?.cardNumber?.message ||
        formState.errors?.cardExpiry?.message ||
        formState.errors?.cardCvc?.message ||
        t("profile.payment-mthd.card-field.empty")}
    </ErrorHint>
  )
}

function BaseStripeForm({ renderError, children, inputStyles }) {
  const { register, control, formState, setError, clearErrors } =
    useFormContext()
  const { t } = useMultilang()
  // FIXME 04/25
  // only trigger for card fields

  const hasIncompleteFields = [
    "cardHolder",
    "cardNumber",
    "cardExpiry",
    "cardCvc",
  ]
    .map((field) => formState.errors?.[field])
    .some(Boolean)
  const getFieldProps = (fieldName) => ({
    fieldName,
    setError,
    clearErrors,
    t,
  })
  const handleCardNumber = handleChange(getFieldProps("cardNumber"))
  const handleCardExpiry = handleChange(getFieldProps("cardExpiry"))
  const handleCardCvc = handleChange(getFieldProps("cardCvc"))
  const hasRenderError = typeof renderError === "function"

  return (
    <ErrorContainer>
      {children}
      <Border error={formState.errors?.cardHolder} className={inputStyles}>
        <input
          {...register("cardHolder", {
            required: true,
          })}
          placeholder={t("credit-card.holder")}
          className="placeholder:text-gray-700 w-full outline-none"
          autoComplete="off"
        />
      </Border>
      <Border error={formState.errors?.cardNumber} className={inputStyles}>
        <Controller
          name="cardNumber"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CardNumberElement
              options={appearance({ placeholder: t("credit-card.number") })}
              className="h-5 leading-5"
              onChange={handleCardNumber(field)}
            />
          )}
        />
      </Border>
      <Border error={formState.errors?.cardExpiry} className={inputStyles}>
        <Controller
          name="cardExpiry"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CardExpiryElement
              options={appearance({
                placeholder: t("credit-card.expiry"),
              })}
              className="h-5 leading-5"
              onChange={handleCardExpiry(field)}
            />
          )}
        />
      </Border>
      <Border error={formState.errors?.cardCvc} className="mb-0">
        <Controller
          name="cardCvc"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CardCvcElement
              options={appearance({ placeholder: t("credit-card.cvc") })}
              className="h-5 leading-5"
              onChange={handleCardCvc(field)}
            />
          )}
        />
      </Border>
      {hasIncompleteFields ? (
        hasRenderError ? (
          renderError(formState)
        ) : (
          <ErrorMsg formState={formState} />
        )
      ) : null}
    </ErrorContainer>
  )
}

export default BaseStripeForm

export function AddNewCard() {
  const { t } = useMultilang()

  return (
    <div className="flex mb-4">
      <img src="/icon/watch/expand.svg" alt="" />
      <p className="ml-2.5 uppercase font-medium text-xs">
        {t("add new card")}
      </p>
    </div>
  )
}
