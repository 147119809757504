const { useRouter } = require("next/router")
import { DateTime } from "luxon"

export const FormatedDate = ({ value }) => {
  const { locale } = useRouter()
  const date = DateTime.fromISO(value)
  let displayDate = date.toFormat("LLL d, yyyy")
  if (locale.match("zh")) displayDate = date.toFormat("yyyy 年 L 月 d 日")
  return displayDate
}
