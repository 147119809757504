import { useMultilang } from "@/hooks/useMultilang"
import Button from "../common/Button"
import Popup from "../common/Popup"

export default function LeavePagePopup({ show, onConfirm, onCancel }) {
  const { t } = useMultilang()

  return (
    <Popup show={show}>
      <div className="p-2.5 max-w-full tablet:max-w-xl">
        <p className="mb-[30px] text-center">{t("popup.alert-leave")}</p>
        <div className="flex justify-center">
          <Button className="min-w-[160px] mr-[15px]" onClick={onConfirm}>
            {t("btn.yes")}
          </Button>
          <Button
            className="min-w-[160px] bg-white text-black-900 border border-black-900 hover:text-gray-700 hover:border-gray-700 hover:bg-white"
            onClick={onCancel}
          >
            {t("btn.no")}
          </Button>
        </div>
      </div>
    </Popup>
  )
}
