import Link from "next/link"

function LinkText({
  children,
  href,
  className,
  rel,
  target,
  openNewTab,
  ...rest
}) {
  const newTabProps = { rel: "noreferrer noopener", target: "_blank" }

  return (
    <Link href={href || ""} {...rest}>
      <a
        className={className || "underline"}
        {...(openNewTab ? newTabProps : { rel, target })}
      >
        {children}
      </a>
    </Link>
  )
}

export default LinkText
