export const MEMBERSHIP_LEVELS = {
  ESSENTIAL: "BASIC_SELLER",
  LUXURY: "LEVEL_2_SELLER",
  PRO: "BASIC_MERCHANT",
  PREMIUM: "LEVEL_2_MERCHANT",
  ELITE: "LEVEL_3_MERCHANT",
}

export const DISPLAY_MEMBERSHIP_LEVELS = {
  BASIC_SELLER: "profile.subscription.level.essential",
  LEVEL_2_SELLER: "profile.subscription.level.luxury",
  BASIC_MERCHANT: "profile.subscription.level.pro",
  LEVEL_2_MERCHANT: "profile.subscription.level.premium",
  LEVEL_3_MERCHANT: "profile.subscription.level.elite",
}

export const MEMBERSHIP_LEVEL_TYPE = {
  BASIC_SELLER: "profile.subscription.level.essential-member",
  LEVEL_2_SELLER: "profile.subscription.level.luxury-member",
  BASIC_MERCHANT: "profile.subscription.level.pro-merchant",
  LEVEL_2_MERCHANT: "profile.subscription.level.premium-merchant",
  LEVEL_3_MERCHANT: "profile.subscription.level.elite-mecrhant",
}

export const MEMBER_MODAL = {
  SELLER: "SELLER_SUBSCRIPTION",
  MERCHANT: "MERCHANT_SUBSCRIPTION",
  RENEW: "RENEW_SUBSCRIPTION",
  CHOOSE: "CHOOSE_SUBSCRIPTION",
}

export const MEMBERSHIP_STATUS = {
  normal: "normal",
  freeTrial: "freeTrial",
  gracePeriod: "gracePeriod",
  postGracePeriod: "postGracePeriod",
  freePeriod: "freePeriod",
}
