import { useMultilang } from "@/hooks/useMultilang"

const LoadMore = ({ hasMore, onClick }) => {
  const { t } = useMultilang()
  if (!hasMore) return <></>

  return (
    <div className="text-center">
      <button
        className="border-button normal-button-padding"
        type="button"
        onClick={onClick}
      >
        {t("btn.load-more")}
      </button>
    </div>
  )
}

export default LoadMore
