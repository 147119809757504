import axios from "axios"
import customAxios from "../axios"

const HOME_PAGE_CONTENT = `${process.env.NEXT_PUBLIC_API_DOMAIN}/api/contents/HOMEPAGE`
const LOGIN_PAGE_CONTENT = `${process.env.NEXT_PUBLIC_API_DOMAIN}/api/contents/SIGNUP_LOGIN`
const TRENDING_NOW = `${process.env.NEXT_PUBLIC_API_DOMAIN}/api/watches/trending_now`
const MODELS_FEATURED = `${process.env.NEXT_PUBLIC_API_DOMAIN}/api/models/featured`
const BRANDS_FEATURED = `${process.env.NEXT_PUBLIC_API_DOMAIN}/api/brands/featured`
const KRONOS_PICKS = `${process.env.NEXT_PUBLIC_API_DOMAIN}/api/watches/kronos_picks`
const LATEST = `${process.env.NEXT_PUBLIC_API_DOMAIN}/api/watches/latest`
export const PRIVACY_POLICY_CONTENT = `/api/contents/PRIVACY_POLICY`
export const TERMS_AND_CONDITION_CONTENT = `/api/contents/TERMS_AND_CONDITIONS`

export const getHomeContent = async () => {
  const endPoint = HOME_PAGE_CONTENT
  const res = await axios.get(endPoint)

  return res.data
}

export const getLoginContent = async () => {
  const endPoint = LOGIN_PAGE_CONTENT
  const res = await axios.get(endPoint)

  return res.data
}

export const getTrendingNow = async () => {
  const endPoint = TRENDING_NOW
  const res = await axios.get(endPoint)

  return res.data
}

export const getCollectionsFeatured = async () => {
  const endPoint = MODELS_FEATURED
  const res = await axios.get(endPoint)

  return res.data
}

export const getBrandsFeatured = async () => {
  const endPoint = BRANDS_FEATURED
  const res = await axios.get(endPoint)

  return res.data
}

export const getKronosPicks = async () => {
  const endPoint = KRONOS_PICKS
  const res = await axios.get(endPoint)

  return res.data
}

export const getLatest = async () => {
  const endPoint = LATEST
  const res = await axios.get(endPoint)

  return res.data
}

export async function getDynamicContent(endPoint) {
  const res = await customAxios.get(endPoint)

  return res.data
}
