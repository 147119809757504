import { useState } from "react"

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState)

  const setHide = () => setState(false)
  const setShow = () => setState(true)
  const toggle = () => setState((prev) => !prev)
  const results = [state, setHide, setShow, toggle]

  results.status = state
  results.setHide = setHide
  results.setShow = setShow
  results.toggle = toggle

  return results
}

export default useToggle
