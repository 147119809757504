export const PRO_MERCHANT = "BASIC_MERCHANT"
export const PREMIUM_MERCHANT = "LEVEL_2_MERCHANT"
export const ELITE_MERCHANT = "LEVEL_3_MERCHANT"

export const ESSENTIAL_SELLER = "BASIC_SELLER"
export const LUXURY_SELLER = "LEVEL_2_SELLER"

export const UPGRADE = "TRANSITION_UPGRADE"
export const DOWNGRADE = "TRANSITION_DOWNGRADE"
export const SELF = "TRANSITION_SELF"

export const MEMBER_SELLER = "SELLER"
export const MEMBER_MERCHANT = "MERCHANT"

export const SUB_ACTIONS = {
  normal: "normal",
  upgrade: "upgrade",
}
