import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import useUserPreference from "./useUserPeference"

const formatter = new Intl.NumberFormat("en-US")

export const formatThousands = formatter.format

const useCurrency = (currentCurrency) => {
  const { preference } = useUserPreference()
  const preferenceCurrency = useMemo(() => preference.currency, [preference])
  const currency = currentCurrency || preferenceCurrency
  const rate = useSelector((state) => {
    return state.content.exchangeRate
  })

  const getPrice = useCallback(
    (amt, fromCurrency) => {
      let amount = amt
      if (currency !== fromCurrency) {
        amount = convertRate(amt, fromCurrency, currency)
      }
      return `${getCurrencyDisplay(currency)}${formatter.format(amount)}`
    },
    [currency]
  )

  const originPrice = (amt, currency) => {
    return `${getCurrencyDisplay(currency)}${formatter.format(amt)}`
  }

  const getRawPrice = (amt, fromCurrency) => {
    const shouldConvert = fromCurrency !== currency
    const price = shouldConvert ? convertRate(amt, fromCurrency, currency) : amt
    return price
  }

  function getCurrencyDisplay(currency) {
    const mapper = {
      USD: "US$",
      HKD: "HK$",
    }
    return mapper[currency] || mapper["USD"]
  }

  function convertRate(amt, from, to) {
    let convertRate = null
    if (to === "HKD") {
      convertRate = rate[from]
    } else {
      if (from === "HKD") {
        convertRate = 1 / rate[to]
      } else {
        // TODO support multicurrency convert
      }
    }
    try {
      return Math.round(amt * convertRate)
    } catch (error) {
      return amt
    }
  }

  return {
    // price: getPrice(currency),
    originPrice,
    price: getPrice,
    currency,
    getRawPrice,
    getCurrencyDisplay,
  }
}

export { useCurrency, formatter }
