import Link from "next/link"
import { useState, Fragment, useRef } from "react"
import { useSelector } from "react-redux"
import { useRouter } from "next/router"
import { DisabledLink } from "@/components/common/Link"
import { id } from "@/services/utils/common"
import { useMultilang } from "@/hooks/useMultilang"
import HighlightLink from "../Navigation/HighlightLink"
import Slogan from "./Slogan"
import { LeavePagePopup, UserPreferencePopup } from "@/components/Popup"
import useUserPreference from "@/hooks/useUserPeference"
import Debug from "debug"
import { LOGIN, PROFILE, PROFILE_PREFERENCES } from "@/constants/route"
import { MERCHANT_STAFF } from "@/constants/merchant"
import { useRole } from "@/hooks/useAuthentication"

const debug = Debug("web:component:Header")

const LINK_MAP = {
  [MERCHANT_STAFF]: PROFILE_PREFERENCES,
  default: PROFILE,
}

export default function Header({ toggle, disabled = false, isOpen }) {
  const [show, setShow] = useState(false)
  const [leavePagePopupShow, setLeavePagePopupShow] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const submitCallback = useRef()
  const router = useRouter()
  const { t } = useMultilang()
  const isLogin = useSelector((state) => state.user.isLogin)
  const { preference, displayLangTranslated } = useUserPreference()
  debug(isLogin)
  const role = useRole()

  const handleToggle = () => {
    if (disabled) {
      return
    }
    toggle()
  }

  function submitUserPreference(cb) {
    if (["/sell/watch"].indexOf(router.route) !== -1) {
      setLeavePagePopupShow(true)
      submitCallback.current = cb
    } else {
      cb()
    }
  }

  const HeaderLink = disabled ? DisabledLink : Link

  const headerLink = isLogin ? LINK_MAP[role] || LINK_MAP.default : LOGIN

  return (
    <Fragment>
      <header>
        <Slogan></Slogan>
        <div className="text-sm text-white bg-green-400 w-full py-5 grid-cols-11 tablet:py-[1.625rem]">
          <div className="container flex justify-between">
            <div className="flex items-center min-w-[18px]">
              <HeaderLink href="/watch" passHref>
                <HighlightLink
                  className="hidden tablet:block mr-12"
                  linkPrefix={["/watch", "/watch/[id]"]}
                  before={t("header.buy")}
                >
                  {t("header.buy")}
                </HighlightLink>
              </HeaderLink>
              <HeaderLink href="/sell" passHref>
                <HighlightLink
                  className="hidden tablet:block"
                  linkPrefix={["/sell", "/sell/watch"]}
                  before={t("header.sell")}
                >
                  {t("header.sell")}
                </HighlightLink>
              </HeaderLink>
            </div>

            <div className="flex items-center">
              <HeaderLink href="/">
                <a className="block w-[190px] tablet:w-[250px]">
                  <img
                    src="/image/header-logo.png"
                    className="cursor-pointer"
                    alt="kronos"
                    width={269}
                    height={30}
                  />
                </a>
              </HeaderLink>
            </div>

            <div className="flex items-center">
              {disabled ? (
                <button
                  type="button"
                  className="hidden tablet:block cursor-default"
                  onClick={id}
                >
                  {displayLangTranslated} | {preference.currency}
                </button>
              ) : (
                <button
                  type="button"
                  className="hidden tablet:block hover:border-b-2 hover:border-ochre-600 hover:font-semibold before:block before:content-[attr(before)] before:h-0 before:invisible before:font-semibold"
                  onClick={() => setShow(true)}
                  before={`${displayLangTranslated} | ${preference.currency}`}
                >
                  {displayLangTranslated} | {preference.currency}
                </button>
              )}
              <div className="tablet:hidden" onClick={handleToggle}>
                <img
                  src={
                    isOpen
                      ? "/icon/my-account-solid.svg"
                      : "/icon/my-account.svg"
                  }
                  alt="my account"
                  width={18}
                  height={18}
                />
              </div>
              <div className="hidden tablet:ml-12 tablet:block">
                <HeaderLink href={headerLink}>
                  <a
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <img
                      src={
                        isHover
                          ? "/icon/my-account-solid.svg"
                          : "/icon/my-account.svg"
                      }
                      alt="my account"
                      width={18}
                      height={18}
                    />
                  </a>
                </HeaderLink>
              </div>
            </div>
          </div>
        </div>
      </header>
      <UserPreferencePopup
        show={show}
        onClose={() => setShow(false)}
        onSubmit={submitUserPreference}
      />
      <LeavePagePopup
        show={leavePagePopupShow}
        onConfirm={() =>
          (submitCallback.current && submitCallback.current()) ||
          setLeavePagePopupShow(false)
        }
        onCancel={() => setLeavePagePopupShow(false) || setShow(false)}
      />
    </Fragment>
  )
}
