import { useEffect } from "react"

const Popup = ({
  show = false,
  popupClass = "",
  tabletHeight = "tablet:max-h-96",
  children,
  isFlex = true,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"
    }
    return () => (document.body.style.overflow = "unset")
  }, [show])

  return (
    <div
      className={`${
        show ? "block opacity-100" : "hidden opacity-0"
      } fixed z-30 ${
        isFlex ? "flex" : ""
      } justify-center items-center overflow-scroll inset-0 bg-[#0b0b0b]/75 transition-opacity duration-500`}
    >
      <div className={`base-popup ${popupClass} ${tabletHeight}`}>
        {children}
      </div>
    </div>
  )
}

export default Popup
