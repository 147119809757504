import { useEffect, useMemo, useRef, useState } from "react"
import useInterval from "@/hooks/useInterval"
import { useMultilang } from "@/hooks/useMultilang"
import { useSelector } from "react-redux"

const Slogan = () => {
  const textFadeRef = useRef(null)
  const [slogan, setSlogan] = useState("")
  const [hyperLink, setHyperLink] = useState("")
  const [currentSloganDataIndex, setCurrentSloganDataIndex] = useState(0)
  const sloganData = useSelector((state) => {
    return state.content.slogans
  })
  const { multilang } = useMultilang()

  const sloganMapping = useMemo(() => {
    return (
      sloganData?.map((item) => ({
        slogan: multilang(item, "content"),
        hyperLink: multilang(item, "hyperlink"),
      })) || []
    )
  }, [sloganData])

  const handleSloganTransition = () => {
    if (sloganMapping.length === currentSloganDataIndex + 1) {
      setCurrentSloganDataIndex(() => 0)
    } else {
      setCurrentSloganDataIndex((preIndex) => preIndex + 1)
    }
    textFadeRef.current.animate({ opacity: [1, 0, 1] }, 3000)

    setTimeout(() => {
      setSlogan(sloganMapping[currentSloganDataIndex].slogan)
      setHyperLink(sloganMapping[currentSloganDataIndex].hyperLink)
    }, 1500)
  }

  useEffect(() => {
    if (sloganMapping.length > 0) {
      setSlogan(sloganMapping[0].slogan)
      setHyperLink(sloganMapping[0].hyperLink)
      setCurrentSloganDataIndex(1)
    }
  }, [sloganMapping])

  useInterval(
    handleSloganTransition,
    sloganMapping.length > 1 ? 10 * 1000 : null
  )

  return (
    <div className="grid bg-[#e4e4e4]">
      {slogan ? (
        <a href={hyperLink} target="_blank" rel="noreferrer">
          <p
            className={`container text-sm text-[#222] font-medium text-center m-auto py-2.5 tracking-[0.21px]`}
            ref={textFadeRef}
          >
            {slogan}
          </p>
        </a>
      ) : null}
    </div>
  )
}

export default Slogan
