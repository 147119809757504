import zip from "lodash/zip"
import {
  ESSENTIAL_SELLER,
  LUXURY_SELLER,
  PRO_MERCHANT,
  PREMIUM_MERCHANT,
  ELITE_MERCHANT,
  DOWNGRADE,
} from "./constants"
import { RAW_LOCALE } from "@/hooks/useMultilang"

import { MEMBER_SELLER, MEMBER_MERCHANT } from "./constants"
import isEqual from "lodash/isEqual"
import { DateTime } from "luxon"
import { compose } from "@reduxjs/toolkit"
import { formatter } from "@/hooks/useCurrency"
import { MEMBERSHIP_STATUS } from "../utils/constants"
import { getStart } from "@/services/utils/common"

export function getMembershipData(type) {
  const typeMap = {
    [MEMBER_SELLER]: [ESSENTIAL_SELLER, LUXURY_SELLER],
    [MEMBER_MERCHANT]: [PRO_MERCHANT, PREMIUM_MERCHANT, ELITE_MERCHANT],
  }
  const keys = typeMap[type]

  return function (data) {
    if (!data?.length || !keys) {
      return []
    }

    const membershipArrToObj = data.reduce((acc, cur) => {
      return { ...acc, [cur.level]: cur }
    }, {})

    return keys.map((key) => membershipArrToObj[key])
  }
}

export function formatPlans(multilang) {
  return function (data) {
    if (!data.length) {
      return []
    }

    return data.map((data) => ({
      label: multilang(data, "name"),
      value: getPlanOptionValue(data),
    }))
  }
}

export function getTermText(t) {
  return function (duration) {
    const durationMap = {
      1: t("profile.subscription.summary-monthly"),
      3: t("profile.subscription.summary-quarterly"),
      12: t("profile.subscription.summary-annually"),
    }
    return durationMap[duration]
  }
}

export function formatTerms(t) {
  const durationMap = {
    1: t("profile.subscription.option-monthly"),
    3: t("profile.subscription.option-quarterly"),
    12: t("profile.subscription.option-annually"),
  }
  return function (data) {
    return data.map((item) => ({
      label: durationMap[item.planDuration],
      value: getTermOptionValue(item),
    }))
  }
}

export function reorderPrice(data) {
  if (!data) {
    return []
  }

  const reordered = data.map((item) => ({
    ...item,
    plans: {
      ...item.plans,
      prices: [...item.plans.prices].sort(
        (a, b) => a.planDuration - b.planDuration
      ),
    },
  }))

  return reordered
}

export function createManageSubscriptionPayload(data) {
  const { plan, term, card, reason, isNewCard } = data

  const payload = {
    level: plan.level,
    planDuration: term?.planDuration || 0,
    paymentMethodId: card,
    reason: reason ? reason : null,
    saveForFutureUsage: isNewCard,
  }

  return payload
}

export function getPlanOptionValue(data) {
  const { level, nameEN, nameTC, nameSC } = data
  return { level, nameEN, nameTC, nameSC }
}

export function getTermOptionValue(data) {
  if (!data) {
    return
  }
  const { planDuration } = data
  return { planDuration }
}

export function createPlanLookup(data) {
  return data.reduce((acc, { plans }) => {
    return { ...acc, [plans.level]: plans.prices?.map(getTermOptionValue) }
  }, {})
}

export function filterTerm(term) {
  const doesNotHaveTerm = Object.values(term).length

  if (!doesNotHaveTerm) {
    return {}
  }

  return { term: getTermOptionValue(term) }
}

const pad = (sign) => (value) => `${sign}${value}`
const padMinusHKD = compose(pad(`\uff0dHK$`), formatter.format)
export const padHKD = compose(pad("HK$"), formatter.format)

export function getSummaryFields(source) {
  return function (plan) {
    if (!Object.values(plan).length) {
      return { fields: [], total: 0 }
    }
    const { newPayment, creditsFromCurrentSubscription, discrepancy } = plan
    const paddedPayment = padHKD(newPayment)
    const paddedCurrentCredit = creditsFromCurrentSubscription
      ? padMinusHKD(creditsFromCurrentSubscription)
      : ""
    const isCurrentUserTerm = newPayment === creditsFromCurrentSubscription
    const total = isCurrentUserTerm ? newPayment : discrepancy
    const value = isCurrentUserTerm
      ? [paddedPayment, ""]
      : [paddedPayment, paddedCurrentCredit]

    return { fields: zip(source, value), total }
  }
}

export function getFieldNames(t) {
  return function (termText) {
    return [termText, t("profile.subscription.current-credit")]
  }
}

export function hasTermChanged({ defaultValues, plan, term }) {
  const { plan: initialPlan, term: initialTerm } = defaultValues

  return isEqual(initialPlan, plan) && !isEqual(initialTerm, term)
}

export function getGracePeriodEnd(date) {
  const endDate = DateTime.fromISO(date).plus({ weeks: 2 }).toISO()

  return endDate
}

const textMap = {
  DOWNGRADE: "profile.subscription.new-subscription-start-date",
  NO_CHANGE: "profile.subscription.next-subscription-start-date",
  NO_SUB: "profile.subscription.subscription-start-date",
  TERM_CHANGE: "profile.subscription.subscription-start-date",
}

function getGracePeriodPaymentDate({
  membershipStatus,
  subscriptionStart,
  status,
  nextPaymentDate,
}) {
  const today = DateTime.fromJSDate(new Date()).toISO()
  const dateMap = {
    [MEMBERSHIP_STATUS.gracePeriod]: subscriptionStart,
    [MEMBERSHIP_STATUS.postGracePeriod]: today,
  }

  if (status === DOWNGRADE) {
    return nextPaymentDate
  }

  return dateMap[membershipStatus]
}

export function getSubscriptionText({
  initialPlan,
  currentPlan,
  membershipStatus,
  status,
  subscriptionDetail,
}) {
  const { card, ...initialPlanWithoutCard } = initialPlan
  const isSamePlanDiffTerm = hasTermChanged({
    defaultValues: initialPlanWithoutCard,
    ...currentPlan,
  })
  const hasInactiveSub = isDuringOrAfterGracePeriod(membershipStatus)
  const { paymentSchedule, subscriptionStart } = subscriptionDetail || {}
  const nextPaymentDate = paymentSchedule?.nextPaymentDate

  if (hasInactiveSub) {
    return {
      text: textMap.NO_SUB,
      date: getGracePeriodPaymentDate({
        membershipStatus,
        subscriptionStart,
        status,
        nextPaymentDate,
      }),
    }
  }

  if (status === DOWNGRADE) {
    return { text: textMap.DOWNGRADE, date: nextPaymentDate }
  }

  if (isSamePlanDiffTerm) {
    return { text: textMap.TERM_CHANGE, date: subscriptionStart }
  }

  return { text: textMap.NO_CHANGE, date: nextPaymentDate }
}

export function getSellerSubscriptionText({
  initialPlan,
  currentPlan,
  status,
  subscriptionDetail,
}) {
  const isSamePlanDiffTerm = hasTermChanged({
    defaultValues: initialPlan,
    ...currentPlan,
  })
  const { paymentSchedule, subscriptionStart } = subscriptionDetail || {}
  const nextPaymentDate = paymentSchedule?.nextPaymentDate

  if (status === DOWNGRADE) {
    return { text: textMap.DOWNGRADE, date: nextPaymentDate }
  }

  if (isSamePlanDiffTerm) {
    return { text: textMap.TERM_CHANGE, date: subscriptionStart }
  }

  return { text: textMap.NO_CHANGE, date: nextPaymentDate }
}

export function getFormDefaultValues(data) {
  const { membership } = data
  const formValues = {
    plan: getPlanOptionValue(membership),
    ...filterTerm(membership.plans.prices),
  }

  return formValues
}

export function getMerchantNextPaymentDate(data) {
  const { freeTrial, paymentSchedule: { nextPaymentDate } = {} } = data
  const paymentDate = freeTrial?.freeTrialDays
    ? DateTime.fromISO(nextPaymentDate)
        .plus({ days: freeTrial.freeTrialDays || 0 })
        .toISO()
    : nextPaymentDate

  return paymentDate
}

export function removeShorterTerms(term) {
  return function (terms) {
    if (!term) {
      return terms
    }

    const duration = term.planDuration

    return terms.filter((item) => item.planDuration >= duration)
  }
}

export function isDuringOrAfterGracePeriod(status) {
  const duringOrAfterGracePeriod = [
    MEMBERSHIP_STATUS.gracePeriod,
    MEMBERSHIP_STATUS.postGracePeriod,
  ]
  const isDuringOrAfterGracePeriod = duringOrAfterGracePeriod.includes(status)

  return isDuringOrAfterGracePeriod
}

export function getLabelText(type) {
  const map = {
    Subscription_start_date: "profile.subscription.subscription-start-date",
    Next_payment_date: "profile.subscription.next-subscription-start-date",
    New_subscription_start_date:
      "profile.subscription.new-subscription-start-date",
    Immediately: "profile.subscription.upgrade-wording",
  }

  return map[type]
}

export function getIsDirty(dirtyFields) {
  return !!Object.keys(dirtyFields).length
}

export function getCreditCard({ defaultCard, savedCards }) {
  const firstSavedCard = getStart(savedCards)
  const firstCardId = firstSavedCard?.id

  const card = defaultCard ? defaultCard : firstCardId

  return card
}

export function isCardDeleted({ payment, savedCards }) {
  const { paymentSchedule } = payment
  const inUseCard = paymentSchedule?.paymentMethodId
  const hasInUseCard = Boolean(inUseCard)
  const isPresent = (savedCards || [])
    .map((card) => card.id)
    .includes(inUseCard)
  const isInUseCardAvailable = hasInUseCard && isPresent

  return isInUseCardAvailable ? inUseCard : undefined
}

export function getIsPostGracePeriod(status) {
  return status === MEMBERSHIP_STATUS.postGracePeriod
}

export function getIsGracePeriod(status) {
  return status === MEMBERSHIP_STATUS.gracePeriod
}

export function getSubTermText({ locale, name, duration }) {
  const SUB_TERM_MAP = {
    [RAW_LOCALE.EN]: `${name} ${duration}`,
    [RAW_LOCALE.ZH_HK]: `${name}${duration}`,
    [RAW_LOCALE.ZH_CN]: `${name}${duration}`,
  }

  return SUB_TERM_MAP[locale]
}

export function getIsFreeTrial(status) {
  return status === MEMBERSHIP_STATUS.freeTrial
}

export function getIsSamePlan({ basePlan, plan }) {
  return basePlan?.plan?.level === plan?.level
}

export function handleDirty(isDirty, membershipStatus) {
  const alwaysEditableStatuses = [
    MEMBERSHIP_STATUS.gracePeriod,
    MEMBERSHIP_STATUS.postGracePeriod,
  ]
  const isDisabled = false
  if (alwaysEditableStatuses.includes(membershipStatus)) {
    return isDisabled
  }

  return isDirty
}

export function shouldSkipPreselectCard(membership) {
  const SKIP_PRESELECT_STATUSES = [
    MEMBERSHIP_STATUS.freeTrial,
    MEMBERSHIP_STATUS.gracePeriod,
    MEMBERSHIP_STATUS.postGracePeriod,
  ]
  const { paymentSchedule, status } = membership || {}
  const { paymentMethodId } = paymentSchedule || {}
  const isSkipStatus = SKIP_PRESELECT_STATUSES.includes(status)
  const skipPreselect = !paymentMethodId || isSkipStatus

  return skipPreselect
}
