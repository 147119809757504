import { Controller } from "react-hook-form"
import ErrorContainer, { ErrorHint } from "@/components/common/ErrorHint"
import { LegendInput, ReactSelect } from "@/components/common/Form"
import UploadDocument from "@/components/Profile/GetPaid/UploadDocument"
import { ProgressWrapper } from "@/components/Profile/Listings/Modal/UploadProgress"
import { Checkbox } from "@/components/common/Form"
import { isUploading } from "@/services/utils/uploadReducer"

function SwitchFormField(props) {
  switch (props.type) {
    case "select":
      return (
        <Controller
          control={props.control}
          name={props.name}
          rules={{
            required: props?.required,
          }}
          render={({ field }) => (
            <ReactSelect
              {...field}
              ref={null}
              options={props.options}
              label={props.label}
              error={props.errors[props.name]}
              onChange={(data) => {
                field.onChange(data.value)
              }}
            />
          )}
        />
      )
    case "text":
      return (
        <LegendInput
          name={props.name}
          displayName={props.label}
          placeholder={props.label}
          register={props.register}
          options={{ required: props?.required }}
          dangerStyle="border-red-100 text-red-100"
          errors={props.errors}
        />
      )
  }
}

export default function FormField(props) {
  const { errors, errorMsg, ...rest } = props

  if (props.type === "upload") {
    const dropzoneProps = props.maxSize ? { maxSize: props.maxSize } : {}

    return (
      <ProgressWrapper handleClick={props.handleClick}>
        {({ percentage, uploadStatus, ...methods }) =>
          props.uri ? null : (
            <ErrorContainer>
              <div className="pt-4 px-4 border">
                <Controller
                  name={props.name}
                  control={props.control}
                  rules={{ required: props?.required }}
                  render={({ field }) => (
                    <UploadDocument
                      disabled={
                        Boolean(percentage) || isUploading(uploadStatus)
                      }
                      upload={props.doUpload({
                        field,
                        ...methods,
                      })}
                      acceptedFileType={props.acceptedFileType}
                      dropzoneProps={dropzoneProps}
                      filesize={props.filesize}
                      mediaType={props.mediaType}
                    />
                  )}
                />
              </div>
              {errors[props.name] ? <ErrorHint>{errorMsg}</ErrorHint> : null}
            </ErrorContainer>
          )
        }
      </ProgressWrapper>
    )
  }

  if (props.type === "checkbox") {
    return (
      <fieldset className="flex items-center">
        <Checkbox
          name={props.name}
          register={props.register}
          id={props.name}
          error={errors?.[props.name]}
          options={{ required: props.required }}
        />
        <label
          htmlFor={props.name}
          className={`ml-2 text-xs ${
            errors?.[props.name] ? "text-red-100" : ""
          }`}
        >
          {props.label}
        </label>
      </fieldset>
    )
  }

  return (
    <ErrorContainer>
      <SwitchFormField {...rest} errors={errors} />
      {errors[props.name] ? <ErrorHint>{errorMsg}</ErrorHint> : null}
    </ErrorContainer>
  )
}
