import { Controller } from "react-hook-form"
import NumberFormat from "react-number-format"
import { startsWithZero } from "@/services/utils/common"
import { getDisplayName } from "@/services/utils/components"

function withNumberFieldFormat(Component) {
  function WithNumberFieldFormat(props) {
    const { control, onChange = Number, ...rest } = props

    return (
      <Controller
        control={control}
        name={props.name}
        rules={rest.options}
        render={({ field, formState }) => (
          <NumberFormat
            {...rest}
            register={() => ({ onBlur: field.onBlur })}
            errors={formState.errors}
            customInput={Component}
            thousandSeparator={true}
            decimalSeparator={false}
            allowNegative={false}
            value={field.value}
            isAllowed={(val) => !startsWithZero(val.value)}
            onValueChange={(e) => {
              const { value } = e
              field.onChange(value ? onChange(value) : value)
            }}
          />
        )}
      />
    )
  }
  const displayName = getDisplayName(Component)
  WithNumberFieldFormat.displayName = `WithNumberFieldFormat ${displayName}`

  return WithNumberFieldFormat
}

export default withNumberFieldFormat
