import { useRouter } from "next/router"
import { forwardRef } from "react"

function HighlightLink(
  { className, linkPrefix, children, before = "", ...rest },
  ref
) {
  const router = useRouter()
  const hightlightClass = checkHightLight()
    ? "border-b-2 border-ochre-600"
    : "hover:border-b-2 hover:border-ochre-600 hover:font-semibold before:block before:content-[attr(before)] before:h-0 before:invisible before:font-semibold text-center"

  function checkHightLight() {
    if (!linkPrefix) return false
    if (linkPrefix.length) {
      for (const l of linkPrefix) {
        if (router.pathname == l) return true
      }
    }
    return false
  }

  return (
    <a
      className={`${className || ""} ${hightlightClass}`}
      ref={ref}
      {...rest}
      before={before}
    >
      {" "}
      {children}{" "}
    </a>
  )
}

export default forwardRef(HighlightLink)
