import useSWR from "swr"
import customAxios, { fetcher } from "@/services/axios"
import axios from "axios"
import { KEYS, local } from "../utils/storage"

export const GET_VERIFY_TIME = "/api/watches/me/verify_times"

export async function getUploadHost(params) {
  const token = local.get(KEYS.ACCESS_TOKEN)

  const res = await axios.get(process.env.NEXT_PUBLIC_OSS_API_DOMAIN, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}

export async function uploadImage(filePath, payload, options = {}) {
  const res = await axios.post(filePath.host, payload, {
    headers: { "Content-Type": "multipart/form-data" },
    ...options,
  })

  return res.data
}

export async function getVerifyTime() {
  const endPoint = GET_VERIFY_TIME
  const res = await customAxios.get(endPoint)

  return res.data
}

export function useVerifyTime() {
  const { data, error } = useSWR(
    GET_VERIFY_TIME,
    (url) => fetcher(url, "get"),
    { revalidateOnMount: true }
  )

  return { data, error }
}

export async function getStsToken() {
  const endPoint = "/api/resource/get_sts"
  const res = await customAxios.get(endPoint)

  return res.data
}
