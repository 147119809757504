import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useRouter } from "next/router"
import * as ROUTES from "@/constants/route"
import { isBrowser } from "@/services/utils/common"
import { MERCHANT_OWNER, MERCHANT_STAFF } from "@/constants/merchant"
import { getIsPostGracePeriod } from "@/components/Profile/Subscription/Modal/utils"

export const useAuthentication = (to = "/") => {
  const router = useRouter()
  const isLogin = useSelector((state) => state.user.isLogin)
  useEffect(() => {
    if (isLogin === false) {
      router.replace(to)
    }
  }, [isLogin, router, to])
}

export const useAuthenticationNotLogin = () => {
  const router = useRouter()
  const isLogin = useSelector((state) => state.user.isLogin)
  useEffect(() => {
    if (isLogin) {
      router.replace("/")
    }
  }, [isLogin, router])
}

export const ProtectedRoute = ({ router, children }) => {
  const isLogin = useSelector((state) => state.user.isLogin)
  const protectedRoutes = [
    ...ROUTES.PROFILE_ROUTES,
    ROUTES.SIGNUP_PROFILE,
    ROUTES.SELL_WATCH_FORM,
  ]
  const isProtectedRoute = protectedRoutes.includes(router.route)
  const shouldRedirect = isBrowser() && !isLogin && isProtectedRoute

  if (shouldRedirect) {
    router.push("/login")
  }
  return children
}

export const useRole = () => {
  const role = useSelector((state) => state.user.userProfile?.loginUser?.role)

  return role
}

export const useLoginUserId = () => {
  const loginId = useSelector((state) => state.user.userProfile?.loginUser.id)
  return loginId
}

export const useIsPostGracePeriod = () => {
  const userSubscriptionDetail = useSubscriptionDetail()
  return getIsPostGracePeriod(userSubscriptionDetail?.status)
}

export function getIsMerchantStaff(role) {
  return role === MERCHANT_STAFF
}

export function getIsMerchantOwner(role) {
  return role === MERCHANT_OWNER
}

export function useSubscriptionDetail() {
  const userSubscriptionDetail = useSelector(
    (state) => state.user.userSubscriptionDetail
  )

  return userSubscriptionDetail
}
