import Link from "next/link"
import { classNames } from "@/services/utils"

export default function LinkButton({ href, children, className, ...rest }) {
  return (
    <Link href={href}>
      <a className={classNames("underline text-gray-700", className)} {...rest}>
        {children}
      </a>
    </Link>
  )
}
