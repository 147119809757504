import { useSelector } from "react-redux"
import { mutate } from "swr"
import { useElements } from "@stripe/react-stripe-js"
import { useStripe } from "@stripe/react-stripe-js"
import { mutateLoginUser, updateUserData } from "@/services/authentication"
import {
  getStripeSetupIntent,
  deletePaymentMethod,
  PAYMENT_METHOD_ENDPOINT,
} from "@/services/payment/stripe"
import { useMultilang } from "@/hooks/useMultilang"
import useToggle from "@/hooks/useToggle"
import { callAll } from "@/services/utils"
import Dropdown from "./dropdown"
import { sendNotification } from "@/services/utils"
import { getExpiry } from "./utils"
import useEffectOnlyOnce from "@/hooks/useEffectOnlyOnce"
import StripeForm, { AddNewCard } from "@/components/common/Stripe/stripeForm"
import { FormProvider, useForm } from "react-hook-form"
import Button, { SubmitButton } from "@/components/common/Button"
import { PROFILE_PAYMENT_METHOD } from "@/constants/route"

function ProfileTitle({ children }) {
  return (
    <h2 className="font-bold uppercase tablet:mb-5 mb-[30px] text-lg tablet:text-sm">
      {children}
    </h2>
  )
}

const setDefaultCreditCard = function (value) {
  return updateUserData({ defaultCreditCardToken: value })
}

const removeDefaultCreditCard = (id) => {
  return setDefaultCreditCard(null)
    .then(() => removeCreditCard(id))
    .then(mutateLoginUser)
}

const removeCreditCard = (id) => {
  return deletePaymentMethod(id).then(refreshPaymentMethods)
}

const refreshPaymentMethods = () => mutate(PAYMENT_METHOD_ENDPOINT)

export const AddCard = ({ handleClick }) => {
  const { t } = useMultilang()

  return (
    <button
      type="button"
      onClick={handleClick}
      className="border p-3.5 flex items-center w-full"
    >
      <img src="/icon/watch/expand.svg" alt="" />
      <span className="uppercase ml-2.5 text-xs">
        {t("profile.payment-mthd.add-card")}
      </span>
    </button>
  )
}

export function AddCardRadio() {
  const { t } = useMultilang()

  return (
    <span className="border p-3.5 flex items-center w-full">
      <img src="/icon/watch/expand.svg" alt="" />
      <span className="uppercase ml-2.5 text-xs">
        {t("profile.payment-mthd.add-card")}
      </span>
    </span>
  )
}

// FIXME 04/25
// review updated implementation
// review sethide and clear holdername field
export const Payments = ({ list, renderPaymentItem }) => {
  return (
    <ul>
      {list?.map(({ card, id }) => {
        const { exp_month, exp_year, last4 } = card
        const expire = getExpiry(exp_month, exp_year)
        const cardText = `**** ${last4}`
        const expireText = `Exp ${expire}`

        return renderPaymentItem({
          ...card,
          id,
          cardText,
          expireText,
        })
      })}
    </ul>
  )
}

export const Payment = (props) => {
  const [toggle, setHide, setShow] = useToggle()
  const { t } = useMultilang()
  const {
    brand,
    cardText,
    expireText,
    id,
    isDefaultCard,
    handleClick,
    handleRemove,
  } = props

  return (
    <li
      className={`border px-4 ${
        isDefaultCard ? "pt-7 tablet:pt-4" : "pt-4"
      } pb-3 mb-4 text-xs relative`}
    >
      {isDefaultCard ? (
        <p className="text-ochre-200 text-xxs absolute top-2 tablet:hidden">
          {t("payment.credit-card.default-card")}
        </p>
      ) : null}
      <div className="flex w-full">
        <p className="uppercase basis-2/6 mr-1 tablet:basis-1/6">{brand}</p>
        <p className="flex items-center">
          {cardText}
          <span className="mx-2">|</span>
          {expireText}
        </p>
        <div className="ml-auto flex">
          {isDefaultCard ? (
            <p className="text-ochre-200 text-xxs hidden tablet:block mr-4">
              {t("payment.credit-card.default-card")}
            </p>
          ) : null}
          <div className="relative">
            <input type="image" src="/icon/hamburger.svg" onClick={setShow} />
            {toggle ? (
              <Dropdown
                onClick={callAll(setHide, () => handleClick(id))}
                onRemove={callAll(setHide, () => handleRemove(id))}
                onClickOutside={setHide}
              />
            ) : null}
          </div>
        </div>
      </div>
    </li>
  )
}

function PrimaryForm({ onSubmit }) {
  const { t } = useMultilang()

  return (
    <div className="text-sm mb-5">
      <form>
        <StripeForm inputStyles="mb-5" />
        <div className="flex">
          <SubmitButton
            className="min-w-[105px] mr-4 tablet:min-w-[142px]"
            submit={onSubmit}
          >
            {t("btn.save")}
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

function SecondaryForm({ onSubmit, onClose }) {
  const { t } = useMultilang()

  return (
    <div className="border border-black-900 text-sm p-4">
      <form className="p-0.5">
        <StripeForm inputStyles="mb-5">
          <AddNewCard />
        </StripeForm>
        <div className="flex">
          <SubmitButton
            className="min-w-[105px] mr-4 tablet:min-w-[142px]"
            submit={onSubmit}
          >
            {t("btn.save")}
          </SubmitButton>

          <Button
            className="min-w-[105px] bg-white text-black-900 border border-black-900 hover:text-gray-700 hover:border-gray-700 hover:bg-white tablet:min-w-[142px]"
            onClick={onClose}
          >
            {t("btn.cancel")}
          </Button>
        </div>
      </form>
    </div>
  )
}

const SavedPayments = function ({ paymentMethods }) {
  const methods = useForm({ shouldUnregister: true })
  const [displayPrimary, hidePrimary] = useToggle(!paymentMethods?.length)
  const [displaySecondary, hideSecondary, showSecondary] = useToggle()
  const elements = useElements()
  const stripe = useStripe()
  const { t } = useMultilang()

  useEffectOnlyOnce(
    hidePrimary,
    (paymentMethod) => paymentMethod?.length,
    paymentMethods
  )

  const defaultCreditCard = useSelector(
    (state) => state.user.loginData.defaultCreditCardToken
  )

  const handleClick = (id) => {
    const isDefaultCreditCard = id === defaultCreditCard
    if (isDefaultCreditCard) {
      return
    }

    setDefaultCreditCard(id)
      .then(mutateLoginUser)
      .catch((err) => console.error(err))
  }

  const handleRemove = (id) => {
    const isDefaultCreditCard = id === defaultCreditCard
    const handler = isDefaultCreditCard
      ? removeDefaultCreditCard
      : removeCreditCard

    handler(id).catch((err) => console.error(err))
  }

  const handleSubmit = async (data) => {
    const { cardHolder } = data
    const cardElement = elements.getElement("cardNumber")
    const { clientSecret } = await getStripeSetupIntent()

    return stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardHolder,
          },
        },
        return_url: `${process.env.NEXT_PUBLIC_DOMAIN}${PROFILE_PAYMENT_METHOD}`,
      })
      .then((result) => (result.error ? Promise.reject(result) : result))
      .then(refreshPaymentMethods)
      .then(() => {
        hidePrimary()
        hideSecondary()
      })
      .catch((err) => {
        console.error(err)
        const message = err?.error?.message || "An error occurred"
        sendNotification(message, "error")
      })
  }

  if (!paymentMethods) {
    return (
      <div className="mb-10">
        <ProfileTitle>
          {t("profile.payment-mthd.saved-payment.title")}
        </ProfileTitle>
      </div>
    )
  }

  return (
    <div className="mb-10">
      <ProfileTitle>
        {t("profile.payment-mthd.saved-payment.title")}
      </ProfileTitle>
      <p className="mb-4">{t("profile.payment-mthd.saved-payment.desc")}</p>

      <div className="tablet:max-w-[564px]">
        <Payments
          list={paymentMethods}
          condition={(id) => id === defaultCreditCard}
          renderPaymentItem={({ id, brand, cardText, expireText }) => (
            <Payment
              id={id}
              key={id}
              brand={brand}
              cardText={cardText}
              expireText={expireText}
              handleClick={handleClick}
              handleRemove={handleRemove}
              isDefaultCard={id === defaultCreditCard}
            />
          )}
        />
        <FormProvider {...methods}>
          {displayPrimary ? (
            <PrimaryForm onSubmit={methods.handleSubmit(handleSubmit)} />
          ) : null}
          {displaySecondary ? (
            <SecondaryForm
              onSubmit={methods.handleSubmit(handleSubmit)}
              onClose={hideSecondary}
            />
          ) : displayPrimary ? null : (
            <AddCard handleClick={showSecondary} />
          )}
        </FormProvider>
      </div>
    </div>
  )
}

export default SavedPayments

const ViewOnlyPayment = (props) => {
  const { t } = useMultilang()
  const { brand, cardText, expireText, isDefaultCard } = props

  return (
    <li
      className={`border px-4 ${
        isDefaultCard ? "pt-7 tablet:pt-4" : "pt-4"
      } pb-3 mb-4 text-xs relative`}
    >
      {isDefaultCard ? (
        <p className="text-ochre-200 text-xxs absolute top-2 tablet:hidden">
          {t("payment.credit-card.default-card")}
        </p>
      ) : null}
      <div className="flex w-full">
        <p className="uppercase basis-2/6 mr-1 tablet:basis-1/6">{brand}</p>
        <p className="flex items-center">
          {cardText}
          <span className="mx-2">|</span>
          {expireText}
        </p>
        <div className="ml-auto flex">
          {isDefaultCard ? (
            <p className="text-ochre-200 text-xxs hidden tablet:block">
              {t("payment.credit-card.default-card")}
            </p>
          ) : null}
        </div>
      </div>
    </li>
  )
}

export function ViewOnlyPayments({ paymentMethods }) {
  const { t } = useMultilang()
  const defaultCreditCard = useSelector(
    (state) => state.user.loginData.defaultCreditCardToken
  )
  const hasPaymentMethod = paymentMethods?.length

  if (!paymentMethods) {
    return (
      <div className="mb-10">
        <ProfileTitle>
          {t("profile.payment-mthd.saved-payment.title")}
        </ProfileTitle>
      </div>
    )
  }

  return (
    <div className="mb-10">
      <ProfileTitle>
        {t("profile.payment-mthd.saved-payment.title")}
      </ProfileTitle>
      <p className="mb-5">{t("profile.payment-mthd.saved-payment.desc")}</p>
      {hasPaymentMethod ? (
        <div className="tablet:max-w-[564px]">
          <Payments
            list={paymentMethods}
            renderPaymentItem={(props) => (
              <ViewOnlyPayment
                {...props}
                key={props.id}
                isDefaultCard={props.id === defaultCreditCard}
              />
            )}
          />
        </div>
      ) : (
        <p className="mb-10 font-medium text-red-100">
          {t("profile.payment-mthd.saved-payment.limited-reminder-empty")}
        </p>
      )}
    </div>
  )
}

export function PaymentFallback() {
  const { t } = useMultilang()

  return (
    <div className="mb-10">
      <ProfileTitle>
        {t("profile.payment-mthd.saved-payment.title")}
      </ProfileTitle>
    </div>
  )
}
