import { useDropzone } from "react-dropzone"
import { sendNotification } from "@/services/utils"
import { Trans } from "next-i18next"
import { useMultilang } from "@/hooks/useMultilang"

export const MEDIA_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
}

const FILE_SIZE_LIMIT_ERROR_MSG = {
  [MEDIA_TYPE.IMAGE]: "error-msg.image-size-invalid",
  [MEDIA_TYPE.VIDEO]: "error-msg.video-size-invalid",
}

const UploadDocument = ({
  upload,
  name = "",
  disabled = false,
  acceptedFileType = "image/png,image/jpg,image/jpeg,.webp,application/pdf",
  dropzoneProps = {},
  filesize,
  mediaType,
}) => {
  const { t } = useMultilang()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileType,
    disabled: disabled,
    multiple: false,
    ...dropzoneProps,
  })
  const { multiple, ...rest } = getInputProps()

  function handleFileExceedLimit(mediaType, filesize) {
    const errorMsg = FILE_SIZE_LIMIT_ERROR_MSG[mediaType]

    return errorMsg
      ? sendNotification(t(errorMsg, { filesize }), "error")
      : null
  }

  function onDrop(droppedFiles, fileReject) {
    if (fileReject.length) {
      const errors = fileReject.flatMap((item) => item.errors)
      const hasExceedFileSizeError = errors.find(
        (error) => error.code === "file-too-large"
      )
      if (mediaType && filesize && hasExceedFileSizeError) {
        return handleFileExceedLimit(mediaType, filesize)
      }
    }

    if (!droppedFiles.length) {
      sendNotification(t("error-msg.file-type"), "error")
    } else {
      upload(droppedFiles[0], name)
    }
  }
  return (
    <div
      className={`mb-[15px] ${disabled ? "cursor-default" : "cursor-pointer"}`}
      {...getRootProps()}
    >
      <label className="flex flex-col justify-center items-center p-[5px] relative border border-dashed border-[#4a4a4a]">
        <button
          className={`w-full text-xs p-2.5 ${
            isDragActive || disabled ? "text-[#d3d3d3]" : "hover:bg-[#d3d3d3]"
          }`}
          disabled={disabled}
          type="button"
        >
          <p className="hidden text-xs tablet:block">
            <Trans
              defaults={t("btn.drag-file")}
              components={{ underline: <span className="underline" /> }}
            ></Trans>
          </p>
          <span className="text-xs underline tablet:hidden">
            {t("btn.click-to-upload")}
          </span>
        </button>
      </label>
      <input {...rest} />
    </div>
  )
}

export default UploadDocument
