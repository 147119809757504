export const getExpiry = (month, year) => {
  const yearEnd = year.toString().slice(2, 4)
  const pad = month < 10 ? `0${month}` : month
  return `${pad}/${yearEnd}`
}

export const pullToHead = (arr, target) => {
  const head = arr?.find((obj) => obj.id === target)
  const rest = arr?.filter((obj) => obj.id !== target)

  if (!head) {
    return arr
  }

  return [head].concat(rest)
}
