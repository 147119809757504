// TODO 01/27
// button needs at least three states
// default button
// polar opposite button
// disabled button
import { classNames } from "@/services/utils"
import Debug from "debug"
import { useState } from "react"
import LoadMore from "./LoadMore"
import LinkButton from "./LinkButton"

const debug = Debug("web:component:button")

const Button = function ({
  children,
  type = "button",
  className,
  disabled = false,
  disabledColor = "disabled:bg-gray-300",
  ...rest
}) {
  const classes = classNames(
    "base-button",
    disabled && disabledColor,
    className
  )
  return (
    <button type={type} className={classes} disabled={disabled} {...rest}>
      {children}
    </button>
  )
}

export default Button

const SubmitButton = ({
  children,
  disabled,
  submit,
  keepLoadingExceptError = false,
  ...args
}) => {
  const [loading, setLoading] = useState(false)

  async function onSubmit(e) {
    debug(e)
    e.preventDefault()
    try {
      setLoading(true)
      await submit()
    } catch (error) {
      debug(error)
      setLoading(false)
    } finally {
      if (!keepLoadingExceptError) setLoading(false)
    }

    return false
  }

  return (
    <Button
      disabled={disabled | loading}
      className="transition-colors"
      onClick={onSubmit}
      {...args}
    >
      {children}
    </Button>
  )
}

const SecondaryButton = ({ className, ...rest }) => {
  return (
    <Button
      className={classNames(
        "bg-white text-black-900 border border-black-900 hover:text-gray-700 hover:border-gray-700 hover:bg-white",
        className
      )}
      {...rest}
    />
  )
}

export { SubmitButton, LoadMore, SecondaryButton, LinkButton }
