import DynamicContent from "./DynamicContent"
import LinkText from "@/components/common/Link/LinkText"
import { TERMS_AND_CONDITION_CONTENT } from "@/services/contents"
import { useMultilang } from "@/hooks/useMultilang"
import { classNames } from "@/services/utils"

function TermsAndCondition({ noUnderline, className, children }) {
  const { t } = useMultilang()

  return (
    <DynamicContent
      endPoint={TERMS_AND_CONDITION_CONTENT}
      render={(link) => (
        <LinkText
          href={link}
          openNewTab
          className={classNames(noUnderline && " ", className)}
        >
          {children ? children : t("footer.t&c")}
        </LinkText>
      )}
    />
  )
}

export default TermsAndCondition
