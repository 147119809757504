import { useEffect, useRef } from "react"

const useEffectOnlyOnce = (callback, condition, dependencies) => {
  const calledOnce = useRef(false)

  useEffect(() => {
    if (calledOnce.current) {
      return
    }

    if (condition(dependencies)) {
      callback(dependencies)
      calledOnce.current = true
    }
  }, [callback, condition, dependencies])

  return calledOnce.current
}

export default useEffectOnlyOnce
