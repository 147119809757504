import { createContext, useState, useContext, useRef, useEffect } from "react"
import { useController } from "react-hook-form"
import { useClickOutside } from "@/hooks/useClickOutside"
import { classNames, getDescendantProp, callAll } from "@/services/utils"

const SelectContext = createContext()

const useSelectContext = function () {
  const context = useContext(SelectContext)
  return context
}

const Select = function ({
  children,
  control,
  name,
  value,
  mode = "light",
  options = { required: true },
  propName,
}) {
  const [isOpen, setOpen] = useState(false)
  const { field } = useController({
    rules: options,
    control,
    name,
  })
  const { value: fieldValue } = field

  useEffect(() => {
    field.onChange(value)
  }, [value])

  return (
    <SelectContext.Provider
      value={{
        isOpen,
        setOpen,
        field,
        propName,
        fieldValue,
        mode,
      }}
    >
      {children}
    </SelectContext.Provider>
  )
}

const Container = function ({ children }) {
  const { isOpen, mode } = useSelectContext()
  const container = useRef()

  const onKeydown = (e) => {
    const option = findOption(container, e.key)
    if (option) option.scrollIntoView()
  }

  function findOption(ref, prefix) {
    const el = ref.current
    if (!el) return
    let result
    const $options = el.querySelectorAll('[data-component="option"]')
    for (const $opt of $options) {
      result = findText($opt, prefix)
      if (result) return result
    }
  }

  function findText($opt, prefix) {
    const regex = new RegExp(`^${prefix}`, "i")
    if ($opt.textContent.match(regex)) {
      return $opt
    }
    for (const $child of $opt.children) {
      let result = findText($child, prefix)
      if (result) return result
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", onKeydown, { capture: true })
    } else {
      document.removeEventListener("keydown", onKeydown, { capture: true })
    }
  }, [isOpen])

  return isOpen ? (
    <div
      className={`absolute w-full max-h-[200px] overflow-scroll top-full left-0 mt-1 border border-gray-700 z-10 ${
        mode === "light" ? "bg-white" : "bg-black-300"
      }`}
      ref={container}
    >
      {children}
    </div>
  ) : null
}

const Trigger = function ({ placeholder = "", className }) {
  const { isOpen, setOpen, propName, fieldValue, mode } = useSelectContext()
  const selectRef = useRef()

  useClickOutside(selectRef, () => setOpen(false))

  return (
    <div
      ref={selectRef}
      onClick={() => setOpen(!isOpen)}
      className={className || "py-[12px] relative"}
    >
      {fieldValue ? (
        <p className={`${mode === "light" ? "text-black-900" : "text-white"}`}>
          {propName ? fieldValue?.[propName] : fieldValue}
        </p>
      ) : (
        <p className="text-xs leading-5 text-gray-300">{placeholder}</p>
      )}
      <div className="absolute flex top-4 right-2">
        <img
          alt=""
          src={`${
            mode === "light"
              ? "/icon/arrow-down.svg"
              : "/icon/arrow-down-white.svg"
          }`}
          height={10}
          width={10}
        />
      </div>
    </div>
  )
}

// FIXME 02/15
// should #f7f7f7 be listed in tailwind custom colors?
function Option(props) {
  const { children, value } = props
  const { setOpen, field, propName, fieldValue, mode } = useSelectContext()
  const bgColor = mode === "light" ? "bg-[#f7f7f7]" : "bg-black-200"
  const isSelected = propName
    ? fieldValue?.[propName] === value[propName]
    : fieldValue === value
  const selectedStyle = isSelected ? bgColor : null
  const className = classNames(
    mode === "light" ? "hover:bg-[#f7f7f7]" : "hover:bg-black-200",
    "p-2 border-b",
    selectedStyle
  )

  const onClick = () => {
    field.onChange(value)
    setOpen(false)
  }

  const handleClick = callAll(props.onClick, onClick)

  return (
    <div onClick={handleClick} className={className} data-component="option">
      {children}
    </div>
  )
}

Option.Container = Container

const SelectWrapper = function ({
  children,
  errors,
  name,
  dangerStyle,
  className,
}) {
  const containerStyles = classNames(
    "relative border pl-2",
    getDescendantProp(errors, name) ? dangerStyle : "border-gray-300",
    className
  )
  return <div className={containerStyles}>{children}</div>
}

export { Select, Trigger, Option, SelectWrapper }
